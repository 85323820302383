import { ParticipantUploadRsvpTypeEnum } from 'src/app/modules/event-v2/types';

export type ParticipantUploadFormState = {
  rsvpType: ParticipantUploadRsvpTypeEnum;
  blobId?: string;
  filename?: string;
  sendQrCode: boolean;
  allowDupParticipants: boolean;
  publishToIrrelevantAgents: boolean;
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<ParticipantUploadFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ParticipantUploadFormState;
    value: any;
  };
};

export type ParticipantUploadFormAction = SetFormStateAction | ModifyFieldAction;

export const participantUploadFormReducer = (
  state: ParticipantUploadFormState,
  action: ParticipantUploadFormAction,
): ParticipantUploadFormState => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};
