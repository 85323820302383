import moment from 'moment';
import { getConfigurations } from './common-utils';

export const getHeaderDate = () => {
  return moment(new Date()).format('MMM DD');
};

// export const getDisplayDate = (date: Date, format?: string) => {
//   return moment(date).format(format ? format : 'DD/MM/YYYY');
// };

export const onDisplayDateFormat = (date: string, format = 'MMMM Do YYYY, h:mm:ss a') => moment(date).format(format);

export const getDefaultDisplayDate = (date?: Date | null, formatKey = 'date') => {
  if (date) {
    const dateFormat = getConfigurations()?.region?.dateFormat;
    let defaultFormat = 'DD/MM/YYYY';
    if (dateFormat) {
      defaultFormat = dateFormat[formatKey] ? dateFormat[formatKey] : dateFormat['date'];
    }
    return moment(date).format(defaultFormat);
  } else {
    return '-';
  }
};

export const getDayStart = (date: Date) => {
  // set to 12:00 am
  return moment(date).startOf('day').toDate();
};

export const getDayEnd = (date: Date) => {
  return moment(date).endOf('day').toDate();
};

export const ignoreTimezone = (date: Date) => {
  return moment(date).format('YYYY-MM-DDThh:mm:ss.sss');
};

export const getIsoString = (date: Date) => {
  return moment(date).toISOString();
};

export const getDayStartForStrapi = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).startOf('day').hour(0).format('YYYY-MM-DDT00:00:00.000');
  return a;
};

export const getDayEndForStrapi = (date: Date) => {
  return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss.sss');
};

export const getLocalDateFormat = (date: Date | string, format?: string) => {
  return moment(date)
    .local()
    .format(format || 'YYYY-MM-DD HH:mm');
};

export const syncDateToTime = (date: Date, time: Date) => {
  const dateValue = moment(date);
  const timeValue = moment(time);
  return dateValue
    .set({
      hour: timeValue.get('hour'),
      minute: timeValue.get('minute'),
    })
    .toDate();
};
