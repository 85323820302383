import moment from 'moment';
import { RegistrationMethodEnum, EventItem, EventSessionItem } from 'src/app/modules/event-v2/types';

export const checkInvalidQuotaFormat = (quota?: string | null, numberOnly?: boolean) => {
  if (quota) {
    const quotaNumber = parseInt(quota);
    return (
      (numberOnly || quota !== 'NA') && (quotaNumber.toString() !== quota || isNaN(quotaNumber) || quotaNumber <= 0)
    );
  }
  return false;
};

export const checkInvalidStartTimeEndTime = (startTime: Date, endTime: Date, arr: EventSessionItem[]) => {
  const timeFormat = 'HH:mm';
  const formattedStartTime = moment(startTime).format(timeFormat);
  const formattedEndTime = moment(endTime).format(timeFormat);

  return arr.some((item) => {
    const itemStartTime = moment(item.startTime).format(timeFormat);
    const itemEndTime = moment(item.endTime).format(timeFormat);
    return itemStartTime < formattedStartTime || itemEndTime > formattedEndTime;
  });
};

export const isNowAtLeastOneDayBefore = (date: Date) => {
  const oneDayBeforeDate = moment(date).subtract(1, 'day');
  return moment().isSameOrBefore(oneDayBeforeDate, 'day');
};

export const stripHtmlTags = (str?: string) => {
  return str ? str.replace(/<[^>]*>/g, '') : '';
};

export const sessionKeyToDisplayName = (key: string) => {
  const sessionNum = key.split('_')[0].split('session')[1];
  const subSessionNum = key.split('_')[1]?.split('sub')[1];
  return subSessionNum ? `Session ${sessionNum} - sub session ${subSessionNum}` : `Session ${sessionNum}`;
};

export const validateRegAllowWalkIn = (eventItem: EventItem) => {
  // return true if pass
  if (eventItem.regMethod === RegistrationMethodEnum.TEMPLATE) {
    return (
      (eventItem.multiSession === false && typeof eventItem.regAllowWalkIn === 'boolean') ||
      (eventItem.multiSession === true &&
        eventItem.sessions.every(
          (session) =>
            (session.multiSubSession === false && typeof session.regAllowWalkIn === 'boolean') ||
            (session.multiSubSession === true &&
              session.subSessions.every((subSession) => typeof subSession.regAllowWalkIn === 'boolean')),
        ))
    );
  } else {
    return true;
  }
};
