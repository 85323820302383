import { makeStyles } from 'tss-react/mui';

const FIELD_WIDTH = 300;

export const useParticipantUploadStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '40px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },
  field: {
    width: FIELD_WIDTH,
    marginTop: 14,
  },
  chooseFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
  },
  fileButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 18,
  },
  templateLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 20,
  },
}));
