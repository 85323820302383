import React, { memo, useCallback, useMemo } from 'react';
import { Button, TextField, CircularProgress } from '@mui/material';
import { useStyles } from './create-schedule.style';
import { CreateScheduleProps, useCreateSchedule } from './create-schedule.hook';
import Form from 'src/app/common/components/Form';
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import dayjs from 'dayjs';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import { disableField } from '../../../util/form.util';
import { ExamType } from './components/exam-type/exam-type.component';
import { RadioGroup } from 'src/app/common/components/RadioGroup';

export const CreateSchedule: React.FC<CreateScheduleProps> = memo((props: CreateScheduleProps) => {
  const Translation = useTranslation();
  const { onCancel, initialValues, disabled, showSubmit } = props;

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const checkDisabled = useCallback((key: CreateScheduleFields) => disableField(key, disabled), [disabled]);

  // custom-hook
  const {
    form,
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examModeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
    onFinish,
    confirmLoading,
    examModeList,
  } = useCreateSchedule(props);

  return (
    <Form form={form} onFinish={onFinish} initialValues={{ ...initialValues }}>
      <div className={styles.wrap}>{Translation('recruitment.exam.schedule.schedule_setting')}</div>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examCodeConfig.name}
        label={examCodeConfig.label}
        rules={examCodeConfig.rules}
      >
        <TextField
          disabled={checkDisabled(examCodeConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={examCodeConfig.placeholder}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examNameConfig.name}
        label={examNameConfig.label}
        rules={examNameConfig.rules}
      >
        <TextField
          disabled={checkDisabled(examNameConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={examNameConfig.placeholder}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={localExamNameConfig.name}
        label={localExamNameConfig.label}
        rules={localExamNameConfig.rules}
      >
        <TextField
          disabled={checkDisabled(localExamNameConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={localExamNameConfig.placeholder}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examTypeConfig.name}
        label={examTypeConfig.label}
        rules={examTypeConfig.rules}
        shouldUpdate
      >
        <ExamType disabled={checkDisabled(examTypeConfig.name)} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examModeConfig.name}
        label={examModeConfig.label}
        rules={examModeConfig.rules}
        shouldUpdate
      >
        <RadioGroup options={examModeList} disabled={checkDisabled(examModeConfig.name)} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examDateConfig.name}
        label={examDateConfig.label}
        rules={examDateConfig.rules}
      >
        <DatePicker
          disabled={checkDisabled(examDateConfig.name)}
          minDate={dayjs()}
          slotProps={{
            textField: {
              variant: 'outlined',
            },
          }}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={startTimeConfig.name}
        label={startTimeConfig.label}
        rules={startTimeConfig.rules}
      >
        <TimePicker
          disabled={checkDisabled(startTimeConfig.name)}
          slotProps={{
            textField: {
              variant: 'outlined',
            },
          }}
          format="HH:mm"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={endTimeConfig.name}
        label={endTimeConfig.label}
        rules={endTimeConfig.rules}
      >
        <TimePicker
          disabled={checkDisabled(endTimeConfig.name)}
          slotProps={{
            textField: {
              variant: 'outlined',
            },
          }}
          format="HH:mm"
        />
      </Form.Item>
      <div className={`${styles.wrap} ${styles.mt30}`}>{Translation('recruitment.exam.schedule.location')}</div>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={venueConfig.name}
        label={venueConfig.label}
        rules={venueConfig.rules}
      >
        <TextField
          disabled={checkDisabled(venueConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={venueConfig.placeholder}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={addressConfig.name}
        label={addressConfig.label}
        rules={addressConfig.rules}
      >
        <TextField
          disabled={checkDisabled(addressConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={addressConfig.placeholder}
        />
      </Form.Item>
      <div className={`${styles.wrap} ${styles.mt30}`}>{Translation('recruitment.exam.schedule.additional')}</div>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={lastRegistrationDateConfig.name}
        label={lastRegistrationDateConfig.label}
        rules={lastRegistrationDateConfig.rules}
      >
        <DateTimePicker
          minDateTime={dayjs()}
          disabled={checkDisabled(lastRegistrationDateConfig.name)}
          slotProps={{
            textField: {
              variant: 'outlined',
            },
          }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={quotaConfig.name}
        label={quotaConfig.label}
        rules={quotaConfig.rules}
      >
        <TextField
          disabled={checkDisabled(quotaConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          type="number"
          placeholder={quotaConfig.placeholder}
          InputProps={{ inputProps: { min: 1 } }}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={remainingQuotaConfig.name}
        label={remainingQuotaConfig.label}
        rules={remainingQuotaConfig.rules}
        required={false}
      >
        <TextField
          disabled={checkDisabled(remainingQuotaConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          type="number"
          placeholder={remainingQuotaConfig.placeholder}
          InputProps={{ inputProps: { min: 0 } }}
        />
      </Form.Item>
      <Form.Item required={false} name={remarkConfig.name} label={remarkConfig.label} rules={remarkConfig.rules}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          disabled={checkDisabled(remarkConfig.name)}
          placeholder={remarkConfig.placeholder}
        />
      </Form.Item>
      <div className={styles.footerContainer}>
        <ButtonDialog
          onOk={onCancel}
          dialogTxt={Translation('component.hint.cancel-warning')}
          btnTxt={Translation('app.button.cancel')}
        />
        {showSubmit ? (
          <Button
            type="submit"
            disabled={confirmLoading}
            style={{ marginLeft: 20 }}
            variant="contained"
            color="secondary"
          >
            {Translation('app.button.confirm')}
            {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
          </Button>
        ) : null}
      </div>
    </Form>
  );
});
