import { makeStyles } from 'tss-react/mui';

const RADIO_GROUP_LABEL_WIDTH = 70;
const TEXT_FIELD_WIDTH = 600;

export const useParticipantDetailStyles = makeStyles()(() => ({
  detailContainer: {
    backgroundColor: 'white',
    padding: '20px 40px',
  },
  backContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10,
  },
  radioGroupLabel: {
    width: RADIO_GROUP_LABEL_WIDTH,
    color: '#3F4254',
  },
  textField: {
    width: TEXT_FIELD_WIDTH,
  },
  bottomSpacing: {
    marginBottom: 10,
  },
}));
