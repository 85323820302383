import { syncDateToTime } from 'src/app/common/utils';
import { EventSessionItem } from 'src/app/modules/event-v2/types';

export const syncDateToSessionsTime = (date: Date, sessions: EventSessionItem[]): EventSessionItem[] => {
  return sessions.map((sessionItem) => ({
    ...sessionItem,
    startTime: syncDateToTime(date, sessionItem.startTime),
    endTime: syncDateToTime(date, sessionItem.endTime),
    subSessions: syncDateToSessionsTime(date, sessionItem.subSessions),
  }));
};

export const checkInvalidSessionsForRSVP = (
  sessions: EventSessionItem[],
  isAnnouncement: boolean,
): { invalid: boolean; errMsg: string } => {
  if (isAnnouncement) {
    return { invalid: false, errMsg: '' };
  }
  const invalidSessions: number[] = [];
  sessions.forEach((sessionItem, index) => {
    if (sessionItem.multiSubSession) {
      if (
        !sessionItem.subSessionEnrollment ||
        sessionItem.subSessions.some((subSessionItem) => !subSessionItem.quota)
      ) {
        invalidSessions.push(index + 1);
      }
    } else {
      if (!sessionItem.quota) {
        invalidSessions.push(index + 1);
      }
    }
  });
  if (invalidSessions.length > 0) {
    return { invalid: true, errMsg: 'Session setting is incomplete' };
  }
  return { invalid: false, errMsg: '' };
};
