import { useEffect, useReducer, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { regionLocale, initI18nGenericData, RegionLocale } from 'src/app/i18n';
import { FormMode } from 'src/app/common/types';
import { syncDateToTime, useErrorHandler, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { StepStatusEnum, ComponentProps, useUpdateChecker } from 'src/app/common/components/pru-stepped-form';
import { convertDataToJSON } from 'src/app/common/components/event-survey-creator/util/creator.util';
import { localeMapping } from 'src/app/common/components/survey-v2/survey-utils';
import {
  EventAccessibilityEnum,
  EventPublishStatusEnum,
  EventModeEnum,
  EventTypeEnum,
  RegistrationMethodEnum,
  EventFormCommonProps,
  EventItem,
} from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, createEventItem, modifyEventItem } from 'src/app/modules/event-v2/network';
import { eventListPath } from '../../../../event-list-routes';
import { BasicsFormState, basicsFormReducer } from '../../../reducers';
import { checkInvalidQuotaFormat, checkInvalidStartTimeEndTime } from '../../../common';
import { syncDateToSessionsTime, checkInvalidSessionsForRSVP } from './basics-form.utils';

type HookProps = ComponentProps<EventFormCommonProps>;

const detailToStateConvertor = (regionLocale: RegionLocale[], eventItem?: EventItem): BasicsFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');
  const initI18nStringArrayData = initI18nGenericData<string[]>(regionLocale, []);
  return eventItem
    ? {
        category: eventItem.category,
        mode: eventItem.mode,
        shareToPublic: eventItem.shareToPublic,
        agentRequired: eventItem.agentRequired,
        type: eventItem.type,
        eventDate: eventItem.eventDate,
        startTime: eventItem.startTime,
        endTime: eventItem.endTime,
        organizer: eventItem.organizer || initI18nStringData,
        name: eventItem.name,
        venue: eventItem.venue,
        tags: eventItem.tags || initI18nStringArrayData,
        onlineMeetingLink: eventItem.onlineMeetingLink,
        onlineMeetingLinkDisplay: eventItem.onlineMeetingLinkDisplay,
        multiSession: eventItem.multiSession,
        sessions: eventItem.multiSession ? eventItem.sessions : [],
        sessionEnrollment: eventItem.sessionEnrollment,
        quota: eventItem.quota,
        regQuotaPerAgent: eventItem.regQuotaPerAgent,
        regAllowWalkIn: eventItem.regAllowWalkIn,
      }
    : {
        category: undefined,
        mode: undefined,
        shareToPublic: undefined,
        agentRequired: undefined,
        type: undefined,
        eventDate: null,
        startTime: null,
        endTime: null,
        organizer: initI18nStringData,
        name: initI18nStringData,
        venue: initI18nStringData,
        tags: initI18nStringArrayData,
        onlineMeetingLink: undefined,
        onlineMeetingLinkDisplay: undefined,
        multiSession: undefined,
        sessions: [],
        sessionEnrollment: undefined,
        quota: undefined,
        regQuotaPerAgent: undefined,
        regAllowWalkIn: undefined,
      };
};

export const useBasicsForm = ({
  formCommonProps,
  onStepChange,
  componentUpdateStepStatus,
  setDiscardChanges,
}: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMode, eventItem, isReset, setChangesUnsaved, setIsReset, reloadData } = formCommonProps;
  const initialState = useMemo(() => detailToStateConvertor(regionLocale, eventItem), [eventItem]);
  const [formState, formDispatch] = useReducer(basicsFormReducer, initialState);

  const isPublicEvent = formState.category?.accessibility === EventAccessibilityEnum.PUBLIC;
  const isAnnouncement = formState.mode === EventModeEnum.ANNOUNCEMENT;
  const isVirtual = formState.type === EventTypeEnum.VIRTUAL || formState.type === EventTypeEnum.HYBRID;

  const { errorState, onSubmitErrorValidator, immediateErrorValidator, onDismissErrorHandler, resetErrorState } =
    useErrorHandler(formState, [
      {
        name: 'category',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'mode',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'shareToPublic',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => isPublicEvent && (formState.shareToPublic === undefined || formState.shareToPublic === null),
      },
      {
        name: 'agentRequired',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () =>
          isPublicEvent &&
          !isAnnouncement &&
          (formState.agentRequired === undefined || formState.agentRequired === null),
      },
      {
        name: 'type',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'eventDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'startTime',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endTime',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endTimeBeforeStartTime',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.startTime && formState.endTime) {
            return !!moment(new Date(formState.startTime)).isSameOrAfter(moment(new Date(formState.endTime)));
          } else {
            return false;
          }
        },
      },
      {
        name: 'onlineMeetingLinkFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () =>
          isVirtual &&
          !!formState.onlineMeetingLink &&
          !(formState.onlineMeetingLink.startsWith('https://') || formState.onlineMeetingLink.startsWith('http://')),
      },
      {
        name: 'multiSession',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'quota',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => !isAnnouncement && formState.multiSession === false && !!!formState.quota,
      },
      {
        name: 'quotaFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () =>
          !isAnnouncement && formState.multiSession === false && checkInvalidQuotaFormat(formState.quota),
      },
      {
        name: 'sessionEnrollment',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => !isAnnouncement && formState.multiSession === true && !!!formState.sessionEnrollment,
      },
      ...(() => {
        let errorFieldDef: ErrorFieldDef[] = [];
        regionLocale.forEach((locale) => {
          errorFieldDef = [
            ...errorFieldDef,
            {
              name: `name-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => !!!formState.name[locale],
            },
            {
              name: `venue-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => !!!formState.venue[locale],
            },
          ];
        });
        return errorFieldDef;
      })(),
    ]);

  const resetFormState = () => {
    formDispatch({
      type: 'SET_FORM_STATE',
      payload: {
        value: initialState,
      },
    });
    resetErrorState();
    componentUpdateStepStatus({
      newStatus: eventItem ? StepStatusEnum.FINISHED : StepStatusEnum.PROGRESS,
      saveChanges: true,
      forceUpdateStatus: true,
    });
    setIsReset(true);
  };

  useEffect(() => {
    setDiscardChanges(resetFormState);
    return () => {
      setDiscardChanges(undefined);
    };
  }, []);

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.startTime, formState.endTime, formState.quota, isVirtual, formState.onlineMeetingLink]);

  useUpdateChecker({ formState, isReset, setChangesUnsaved, setIsReset });

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formState.multiSession) {
        if (formState.sessions.length === 0) {
          componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.WARNING,
                title: 'Notice',
                content: 'Please set up at least one session.',
              },
            ]),
          );
          return;
        } else {
          const { invalid, errMsg } = checkInvalidSessionsForRSVP(formState.sessions, isAnnouncement);
          if (invalid) {
            componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  content: errMsg,
                },
              ]),
            );
            return;
          } else if (
            checkInvalidStartTimeEndTime(formState.startTime as Date, formState.endTime as Date, formState.sessions)
          ) {
            componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  content: 'Session start time and end time should be within event start time and end time.',
                },
              ]),
            );
            return;
          }
        }
      }
      const body: CreateUpdateEventBody = {
        category: formState.category?._id,
        mode: formState.mode,
        shareToPublic: formState.shareToPublic,
        agentRequired: formState.agentRequired,
        type: formState.type,
        eventDate: formState.eventDate,
        startTime: syncDateToTime(formState.eventDate as Date, formState.startTime as Date),
        endTime: syncDateToTime(formState.eventDate as Date, formState.endTime as Date),
        organizer: formState.organizer,
        name: formState.name,
        venue: formState.venue,
        tags: formState.tags,
        onlineMeetingLink: formState.onlineMeetingLink,
        onlineMeetingLinkDisplay: formState.onlineMeetingLinkDisplay,
        multiSession: formState.multiSession,
        sessions: formState.multiSession ? syncDateToSessionsTime(formState.eventDate as Date, formState.sessions) : [],
        sessionEnrollment: formState.sessionEnrollment,
        quota: formState.quota,
        publishStatus: eventItem?.publishStatus ? undefined : EventPublishStatusEnum.DRAFT,
        regFormBody:
          eventItem && eventItem.regMethod === RegistrationMethodEnum.TEMPLATE
            ? convertDataToJSON({ ...eventItem, ...formState } as EventItem)
            : undefined,
        regQuotaPerAgent: formState.regQuotaPerAgent,
        regAllowWalkIn: formState.regAllowWalkIn,
        walkInFormBody: eventItem?.walkInFormBody
          ? { ...eventItem.walkInFormBody, title: localeMapping(formState.name) }
          : undefined,
      };
      try {
        if (formMode === FormMode.CREATE) {
          const res = await createEventItem(body, dispatch);
          history.replace(`${eventListPath}/edit/${res._id}`);
        } else if (eventItem) {
          await modifyEventItem(eventItem._id, body, dispatch);
          await reloadData();
        }
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Data saved successfully',
            },
          ]),
        );
        onStepChange({
          newActiveStep: { stepIndex: 0, child: { stepIndex: 1 } },
          currentStepStatus: StepStatusEnum.FINISHED,
          forceNavigation: true,
          saveChanges: true,
        });
      } catch (err) {}
    } else {
      componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
    }
  };

  return {
    formState,
    errorState,
    isPublicEvent,
    isAnnouncement,
    isVirtual,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};
