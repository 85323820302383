import { I18nGenericData } from 'src/app/i18n';
import { SessionTypeEnum } from './event-types';

export enum ParticipantPageEnum {
  LIST = 'LIST',
  DETAIL = 'DETAIL',
  UPLOAD = 'UPLOAD',
}

export enum ParticipantRoleEnum {
  AGENT = 'AGENT',
  PROSPECT = 'PROSPECT',
  CANDIDATE = 'CANDIDATE',
  ANONYMOUS = 'ANONYMOUS',
}

export enum ParticipantContactEnum {
  MOBILE_EMAIL = 'MOBILE_EMAIL',
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
}

export enum CreatedViaEnum {
  FORM = 'FORM',
  INPUT = 'INPUT',
  UPLOAD = 'UPLOAD',
}

export enum RegistrationStatusEnum {
  REGISTERED = 'REGISTERED',
  CANCELLED = 'CANCELLED',
}

export enum AttendanceStatusEnum {
  NOT_ATTENDED = 'NOT_ATTENDED',
  ATTENDED = 'ATTENDED',
  CANCELLED = 'CANCELLED',
}

export enum RegistrationTypeEnum {
  RSVP = 'RSVP',
  WALKIN = 'WALKIN',
}

export enum ParticipantUploadRsvpTypeEnum {
  REGISTRANT = 'REGISTRANT',
  ATTENDEE = 'ATTENDEE',
}

export enum ParticipantTemplateTypeEnum {
  REGISTRANT = 'REGISTRANT',
  ATTENDEE = 'ATTENDEE',
  ATTENDEE_WITH_DATA = 'ATTENDEE_WITH_DATA',
}

export type ParticipantPageState = {
  page: ParticipantPageEnum;
  title: string;
  participantDetail?: ParticipantDetailItem;
};

export type ParticipantOverview = {
  eventId: string;
  quota?: string;
  registered: string;
  attended?: string;
  attendanceRate?: string;
  walkin?: string;
  sessions: SessionOverview[];
};

export type SessionOverview = {
  _id: string;
  key: string;
  type: SessionTypeEnum;
  name: I18nGenericData<string>;
  quota: string;
  registered: string;
  attended: string;
  attendanceRate: string;
  walkin?: string;
};

export type ParticipantItem = {
  registrationId: string;
  firstName: string;
  lastName: string;
  role: ParticipantRoleEnum;
  referer: string;
  agentCode?: string;
  contact: ParticipantContactEnum;
  registrationTime: Date;
  qrCode: string;
  createdFrom: CreatedViaEnum;
  createdBy: string;
  lastUpdatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  status: RegistrationStatusEnum;
  attendanceStatus?: AttendanceStatusEnum;
};

export type ParticipantDetailItem = Omit<ParticipantItem, 'attendanceStatus'> & {
  phoneNumber?: string;
  email?: string;
  isAttended: boolean;
  checkInAt?: Date;
  checkOutAt?: Date;
  duration?: string;
  sessions?: ParticipantSessionItem[];
};

export type ParticipantSessionItem = {
  key: string;
  name: I18nGenericData<string>;
  startTime: Date;
  endTime: Date;
  isRegistered: boolean;
  isAttended: boolean;
  attendeeId?: string;
  checkInAt?: Date;
  checkOutAt?: Date;
  duration?: string;
  subSessions?: ParticipantSessionItem[];
};

export type RemoveDialogState = {
  open: boolean;
  id?: string;
};
