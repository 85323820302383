import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { AlertItem, dismissAlertItem } from '@pruforce/common-adminweb-sdk';
import { useStyles } from './pru-alert.style';

type PruAlertProps = {
  alertItems: AlertItem[];
};

export const PruAlert: FC<PruAlertProps> = ({ alertItems }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.alertContainer}>
      {alertItems &&
        alertItems.map((alertItem, index) => {
          setTimeout(() => dispatch(dismissAlertItem({ index })), 5000);
          const onClose = () => {
            dispatch(dismissAlertItem({ index }));
          };
          return (
            <Alert
              key={`alert-item-${index}`}
              severity={alertItem.severity}
              action={alertItem.action ? alertItem.action(onClose) : undefined}
              onClose={onClose}
            >
              {alertItem.title && <AlertTitle>{alertItem.title}</AlertTitle>}
              <div className={classes.alertContent}>{alertItem.content}</div>
            </Alert>
          );
        })}
    </div>
  );
};
