import React, { useCallback, useMemo } from 'react';
import { createLicenseExamSchedule } from 'src/app/modules/Recruitment/network/license-exam-crud';
import { useDispatch } from 'react-redux';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import { useHistory } from 'react-router-dom';
import { formatParams } from '../util/api-helper.util';

export const useAddSchedule = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = useMemo(
    () => ({
      examCode: '',
      examType: null,
      examMode: null,
      examNameEnglish: '',
      examNameLocal: '',
      examDate: null,
      examCentre: '',
      startTime: null,
      endTime: null,
      lastRegistrationDate: null,
      quota: '',
      minimumQuota: '',
      address: '',
      venue: '',
      remarks: '',
    }),
    [],
  );

  const onSubmit = useCallback(async (values: Record<CreateScheduleFields, any>) => {
    try {
      const params = formatParams(values);
      await createLicenseExamSchedule({ payload: [params] }, dispatch);
      history.goBack();
    } catch (err) {
      console.error('createLicenseExamSchedule error: ', err);
    }
  }, []);

  return {
    initialValues,
    onSubmit,
  };
};
