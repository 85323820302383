import { FC } from 'react';
import moment from 'moment';
import { map, filter } from 'lodash';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  TextField,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { regionLocale, useLang, initI18nGenericData } from 'src/app/i18n';
import {
  MANDATORY_FIELD_ERROR_TEXT,
  TIME_ERROR_TEXT,
  NUMBER_NA_ONLY_ERROR_TEXT,
  trueFalseOptions,
} from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import { PruDatePicker, PruTimePicker } from 'src/app/common/components/PruDatePicker';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventCategoryItem,
  EventModeEnum,
  EventTypeEnum,
  OnlineMeetingLinkDisplayEnum,
  EventFormCommonProps,
} from 'src/app/modules/event-v2/types';
import { eventListPath } from '../../../../event-list-routes';
import { BasicsFormState } from '../../../reducers';
import { useCommonFormStyles, MultiLangAccordion } from '../../../common';
import { useBasicsStyles } from './basics-form.style';
import { useBasicsForm } from './basics-form.hook';
import { SessionSetting } from './components/session-setting';

type EventBasicsFormProps = ComponentProps<EventFormCommonProps>;

export const EventBasicsForm: FC<EventBasicsFormProps> = ({ formCommonProps, ...rest }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useBasicsStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, isPublished, disableEdit, eventCategoryList, eventTagList } = formCommonProps;
  const {
    formState,
    errorState,
    isPublicEvent,
    isAnnouncement,
    isVirtual,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  } = useBasicsForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.VIEW;

  const renderEventModeField = () => (
    <div className={commonFormClasses.fieldContainer}>
      <FormControl
        disabled={viewMode || isPublished || disableEdit}
        className={commonFormClasses.inputLabelField}
        variant="standard"
        error={errorState.mandatory.mode}
      >
        <InputLabel id="mode-label" shrink className={commonFormClasses.inputLabel}>
          {Translation('event.form.event_mode')}
          <span className={commonFormClasses.mandatory}>*</span>
        </InputLabel>
        <Select
          labelId="mode-label"
          displayEmpty
          value={formState.mode || ''}
          renderValue={(selected) =>
            selected ? (
              Translation(`event.form.event_mode.${selected.toLowerCase()}`)
            ) : (
              <span className={commonFormClasses.placeholder}>
                {Translation('app.input.placeholder.please-select')}
              </span>
            )
          }
          onChange={(e) => {
            onDismissErrorHandler(['mode', 'agentRequired', 'quota'], true);
            const selectedMode = e.target.value as EventModeEnum;
            const newFormState: Partial<BasicsFormState> = {
              mode: selectedMode,
            };
            if (selectedMode === EventModeEnum.ANNOUNCEMENT) {
              newFormState.agentRequired = null;
              newFormState.quota = null;
            }
            formDispatch({
              type: 'SET_FORM_STATE',
              payload: {
                value: newFormState,
              },
            });
          }}
        >
          {map(EventModeEnum, (option) => (
            <MenuItem key={option} value={option}>
              {Translation(`event.form.event_mode.${option.toLowerCase()}`)}
            </MenuItem>
          ))}
        </Select>
        {errorState.mandatory.mode && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
      </FormControl>
    </div>
  );

  return (
    <div className={commonFormClasses.container}>
      <div>
        <div className={commonFormClasses.sectionContainer}>
          <div className={commonFormClasses.titleContainer}>{Translation('event.form.event_purpose')}</div>
          <div className={commonFormClasses.contentContainer}>
            <div className={commonFormClasses.fieldContainer}>
              <FormControl
                disabled={viewMode || isPublished || disableEdit}
                className={commonFormClasses.inputLabelField}
                variant="standard"
                error={errorState.mandatory.category}
              >
                <InputLabel id="category-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.common.category')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <Select
                  labelId="category-label"
                  displayEmpty
                  value={
                    formState.category
                      ? eventCategoryList.find((categoryItem) => categoryItem._id === formState.category?._id)
                      : ''
                  }
                  renderValue={(selected) =>
                    selected ? (
                      selected.name[locale]
                    ) : (
                      <span className={commonFormClasses.placeholder}>
                        {Translation('app.input.placeholder.please-select')}
                      </span>
                    )
                  }
                  onChange={(e) => {
                    onDismissErrorHandler(['category', 'shareToPublic', 'agentRequired'], true);
                    const selectedCategory = e.target.value as EventCategoryItem;
                    const newFormState: Partial<BasicsFormState> = {
                      category: selectedCategory,
                    };
                    if (selectedCategory.accessibility !== EventAccessibilityEnum.PUBLIC) {
                      newFormState.shareToPublic = null;
                      newFormState.agentRequired = null;
                      newFormState.regQuotaPerAgent = null;
                    }
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: {
                        value: newFormState,
                      },
                    });
                  }}
                >
                  {eventCategoryList.map((categoryItem) => (
                    <MenuItem key={categoryItem._id} value={categoryItem as any}>
                      {categoryItem.name[locale]}
                    </MenuItem>
                  ))}
                </Select>
                {errorState.mandatory.category && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
            <div className={commonFormClasses.fieldContainer}>
              <FormControl disabled={true} className={commonFormClasses.radioGroupContainer}>
                <FormLabel className={classes.radioGroupLabel}>{Translation('event.common.accessibility')}</FormLabel>
                <RadioGroup className={commonFormClasses.radioGroup} value={formState.category?.accessibility || ''}>
                  {map(EventAccessibilityEnum, (option) => (
                    <FormControlLabel
                      className={commonFormClasses.formControlLabel}
                      key={`accessibility-${option}`}
                      control={<Radio />}
                      label={Translation(`event.common.accessibility.${option.toLowerCase()}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            {isPublicEvent ? (
              <>
                <div className={commonFormClasses.fieldContainer}>
                  <FormControl disabled={true} className={commonFormClasses.radioGroupContainer}>
                    <FormLabel className={classes.radioGroupLabel}>{Translation('event.common.audience')}</FormLabel>
                    <RadioGroup className={commonFormClasses.radioGroup} value={formState.category?.audience || ''}>
                      <FormControlLabel
                        className={commonFormClasses.formControlLabel}
                        key={`audience-${EventAudienceEnum.SALES_PROSPECT}`}
                        control={<Radio />}
                        label={Translation(`event.common.audience.${EventAudienceEnum.SALES_PROSPECT.toLowerCase()}`)}
                        value={EventAudienceEnum.SALES_PROSPECT}
                      />
                      <FormControlLabel
                        className={commonFormClasses.formControlLabel}
                        key={`audience-${EventAudienceEnum.RECRUITMENT_PROSPECT}`}
                        control={<Radio />}
                        label={Translation(
                          `event.common.audience.${EventAudienceEnum.RECRUITMENT_PROSPECT.toLowerCase()}`,
                        )}
                        value={EventAudienceEnum.RECRUITMENT_PROSPECT}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {renderEventModeField()}
                <div className={commonFormClasses.fieldContainer}>
                  <FormControl
                    disabled={viewMode || isPublished || disableEdit}
                    className={commonFormClasses.inputLabelField}
                    variant="standard"
                    error={errorState.mandatory.shareToPublic}
                  >
                    <InputLabel id="shareToPublic-label" shrink className={commonFormClasses.inputLabel}>
                      {Translation('event.form.share_to_public')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </InputLabel>
                    <Select
                      labelId="shareToPublic-label"
                      displayEmpty
                      value={typeof formState.shareToPublic === 'boolean' ? formState.shareToPublic.toString() : ''}
                      renderValue={(selected) =>
                        selected ? (
                          Translation(`event.form.share_to_public.${selected}`)
                        ) : (
                          <span className={commonFormClasses.placeholder}>
                            {Translation('app.input.placeholder.please-select')}
                          </span>
                        )
                      }
                      onChange={(e) => {
                        onDismissErrorHandler('shareToPublic', true);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'shareToPublic',
                            value: e.target.value === 'true' ? true : false,
                          },
                        });
                      }}
                    >
                      {trueFalseOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {Translation(`event.form.share_to_public.${option}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errorState.mandatory.shareToPublic && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                {!isAnnouncement && (
                  <div className={commonFormClasses.fieldContainer}>
                    <FormControl
                      disabled={viewMode || isPublished || disableEdit}
                      className={commonFormClasses.inputLabelField}
                      variant="standard"
                      error={errorState.mandatory.agentRequired}
                    >
                      <InputLabel id="agentRequired-label" shrink className={commonFormClasses.inputLabel}>
                        {Translation('event.form.agent_required')}
                        <span className={commonFormClasses.mandatory}>*</span>
                      </InputLabel>
                      <Select
                        labelId="agentRequired-label"
                        displayEmpty
                        value={typeof formState.agentRequired === 'boolean' ? formState.agentRequired.toString() : ''}
                        renderValue={(selected) =>
                          selected ? (
                            Translation(`event.form.agent_required.${selected}`)
                          ) : (
                            <span className={commonFormClasses.placeholder}>
                              {Translation('app.input.placeholder.please-select')}
                            </span>
                          )
                        }
                        onChange={(e) => {
                          onDismissErrorHandler('agentRequired', true);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'agentRequired',
                              value: e.target.value === 'true' ? true : false,
                            },
                          });
                        }}
                      >
                        {trueFalseOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {Translation(`event.form.agent_required.${option}`)}
                          </MenuItem>
                        ))}
                      </Select>
                      {errorState.mandatory.agentRequired && (
                        <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                )}
              </>
            ) : (
              renderEventModeField()
            )}
          </div>
        </div>
        <div className={commonFormClasses.sectionContainer}>
          <div className={commonFormClasses.titleContainer}>{Translation('event.form.event_info')}</div>
          <div className={commonFormClasses.contentContainer}>
            <div className={commonFormClasses.fieldContainer}>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.inputLabelField}
                variant="standard"
                error={errorState.mandatory.type}
              >
                <InputLabel id="type-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.form.event_type')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <Select
                  labelId="type-label"
                  displayEmpty
                  value={formState.type || ''}
                  renderValue={(selected) =>
                    selected ? (
                      Translation(`event.form.event_type.${selected.toLowerCase()}`)
                    ) : (
                      <span className={commonFormClasses.placeholder}>
                        {Translation('app.input.placeholder.please-select')}
                      </span>
                    )
                  }
                  onChange={(e) => {
                    const dismissFields = ['type'];
                    const newFormState: Partial<BasicsFormState> = {
                      type: e.target.value as EventTypeEnum,
                    };
                    if (e.target.value === EventTypeEnum.VIRTUAL) {
                      regionLocale.forEach((locale) => {
                        dismissFields.push(`venue-${locale}`);
                      });
                      newFormState.venue = initI18nGenericData<string>(regionLocale, 'Online');
                    }
                    onDismissErrorHandler(dismissFields, true);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: {
                        value: newFormState,
                      },
                    });
                  }}
                >
                  {map(EventTypeEnum, (option) => (
                    <MenuItem key={option} value={option}>
                      {Translation(`event.form.event_type.${option.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errorState.mandatory.type && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
            <div className={commonFormClasses.fieldContainer}>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.inputLabelField}
                error={errorState.mandatory.eventDate}
              >
                <InputLabel id="eventDate-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.common.event_date')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <PruDatePicker
                  disablePast
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.datePicker}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      error: errorState.mandatory.eventDate,
                      helperText: errorState.mandatory.eventDate && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={formState.eventDate}
                  onChange={(date) => {
                    onDismissErrorHandler('eventDate', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'eventDate', value: date } });
                  }}
                />
              </FormControl>
            </div>
            <div className={commonFormClasses.doubleFieldContainer}>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.doubleField}
                error={errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime}
              >
                <InputLabel id="startTime-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.form.event_start_time')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <PruTimePicker
                  disablePast={moment(formState.eventDate).isSame(moment(), 'day')}
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.datePicker}
                  ampm={false}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      error: errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime,
                      helperText: errorState.mandatory.startTime && MANDATORY_FIELD_ERROR_TEXT,
                      InputProps: {
                        endAdornment: <></>,
                      },
                    },
                  }}
                  value={formState.startTime}
                  onChange={(date) => {
                    onDismissErrorHandler('startTime', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: date } });
                  }}
                />
              </FormControl>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.doubleField}
                error={errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime}
              >
                <InputLabel id="endTime-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.form.event_end_time')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <PruTimePicker
                  disablePast={moment(formState.eventDate).isSame(moment(), 'day')}
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.datePicker}
                  ampm={false}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      error: errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime,
                      helperText: errorState.immediate.endTimeBeforeStartTime
                        ? TIME_ERROR_TEXT
                        : errorState.mandatory.endTime && MANDATORY_FIELD_ERROR_TEXT,
                      InputProps: {
                        endAdornment: <></>,
                      },
                    },
                  }}
                  value={formState.endTime}
                  onChange={(date) => {
                    onDismissErrorHandler('endTime', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: date } });
                  }}
                />
              </FormControl>
            </div>
            <div className={commonFormClasses.fieldContainer}>
              <MultiLangAccordion
                disabled={viewMode || disableEdit}
                error={(locale) => errorState.mandatory[`name-${locale}`] || errorState.mandatory[`venue-${locale}`]}
                fillInOnChange={(e, locale) => {
                  if (e.target.checked) {
                    onDismissErrorHandler([`name-${locale}`, `venue-${locale}`], true);
                    formDispatch({
                      type: 'FILL_IN_DETAIL',
                      payload: { fromLocale: regionLocale[0], toLocale: locale },
                    });
                  }
                }}
                renderChildren={(locale) => (
                  <>
                    <div className={commonFormClasses.fieldContainer}>
                      <TextField
                        disabled={viewMode || disableEdit}
                        fullWidth
                        variant="standard"
                        label={Translation('event.form.event_organizer')}
                        placeholder={Translation('event.form.event_organizer.placeholder')}
                        InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                        value={formState.organizer[locale] || ''}
                        onChange={(e) => {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'organizer',
                              value: { ...formState.organizer, [locale]: e.target.value },
                            },
                          });
                        }}
                      />
                    </div>
                    <div className={commonFormClasses.fieldContainer}>
                      <TextField
                        disabled={viewMode || disableEdit}
                        fullWidth
                        variant="standard"
                        label={
                          <>
                            {Translation('event.common.name')}
                            <span className={commonFormClasses.mandatory}>*</span>
                          </>
                        }
                        placeholder={Translation('event.form.name.placeholder')}
                        InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                        inputProps={{ maxLength: 80 }}
                        error={errorState.mandatory[`name-${locale}`]}
                        helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.name[locale] || ''}
                        onChange={(e) => {
                          onDismissErrorHandler(`name-${locale}`, e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                          });
                        }}
                      />
                    </div>
                    <div className={commonFormClasses.fieldContainer}>
                      <TextField
                        disabled={viewMode || disableEdit || formState.type === EventTypeEnum.VIRTUAL}
                        fullWidth
                        variant="standard"
                        label={
                          <>
                            {Translation('event.list.detail.venue')}
                            <span className={commonFormClasses.mandatory}>*</span>
                          </>
                        }
                        placeholder={Translation('event.form.venue.placeholder')}
                        InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                        error={errorState.mandatory[`venue-${locale}`]}
                        helperText={errorState.mandatory[`venue-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.venue[locale] || ''}
                        onChange={(e) => {
                          onDismissErrorHandler(`venue-${locale}`, e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'venue', value: { ...formState.venue, [locale]: e.target.value } },
                          });
                        }}
                      />
                    </div>
                    <div className={classes.tagFieldContainer}>
                      <Autocomplete
                        disabled={viewMode || disableEdit}
                        multiple
                        freeSolo
                        filterSelectedOptions
                        disablePortal
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'flip',
                                enabled: false,
                              },
                              {
                                name: 'preventOverflow',
                                enabled: false,
                              },
                            ],
                          },
                        }}
                        options={eventTagList.map((tagItem) => tagItem.name)}
                        renderTags={(value: readonly string[], getTagProps) =>
                          value.map((option: string, index: number) => (
                            <Chip variant="filled" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label={Translation('event.list.detail.tags')}
                            InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                          />
                        )}
                        value={formState.tags[locale] || []}
                        onChange={(_, newValue) => {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'tags', value: { ...formState.tags, [locale]: newValue } },
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              />
            </div>
            {isVirtual && (
              <>
                <div className={commonFormClasses.fieldContainer}>
                  <TextField
                    disabled={viewMode || disableEdit}
                    fullWidth
                    variant="standard"
                    label={Translation('event.form.online_meeting_link')}
                    placeholder={Translation('event.form.online_meeting_link.placeholder')}
                    InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                    error={errorState.immediate.onlineMeetingLinkFormat}
                    helperText={errorState.immediate.onlineMeetingLinkFormat && Translation('component.form-link-text')}
                    value={formState.onlineMeetingLink || ''}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'onlineMeetingLink', value: e.target.value },
                      });
                    }}
                  />
                  <div className={commonFormClasses.remark}>{Translation('event.form.online_meeting_link.remark')}</div>
                </div>
                <div className={commonFormClasses.fieldContainer}>
                  <FormControl disabled={viewMode || disableEdit}>
                    <FormLabel className={commonFormClasses.formLabel}>
                      {Translation('event.form.online_meeting_link_display')}
                    </FormLabel>
                    <FormGroup className={commonFormClasses.radioGroup}>
                      {map(
                        filter(
                          OnlineMeetingLinkDisplayEnum,
                          (option) =>
                            !isAnnouncement || (isAnnouncement && option === OnlineMeetingLinkDisplayEnum.EVENT_DETAIL),
                        ),
                        (option) => (
                          <FormControlLabel
                            key={`onlineMeetingLinkDisplay-${option}`}
                            className={commonFormClasses.formControlLabel}
                            control={
                              <Checkbox
                                checked={!!formState.onlineMeetingLinkDisplay?.includes(option)}
                                onChange={(e) =>
                                  formDispatch({
                                    type: 'MODIFY_FIELD',
                                    payload: {
                                      field: 'onlineMeetingLinkDisplay',
                                      value: e.target.checked
                                        ? [...(formState.onlineMeetingLinkDisplay || []), option]
                                        : formState.onlineMeetingLinkDisplay?.filter((item) => item !== option),
                                    },
                                  })
                                }
                              />
                            }
                            label={
                              <>
                                {Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}`)}
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  title={Translation(
                                    `event.form.online_meeting_link_display.${option.toLowerCase()}.tooltip`,
                                  )}
                                >
                                  <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                                    <Info />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            labelPlacement="end"
                          />
                        ),
                      )}
                    </FormGroup>
                  </FormControl>
                </div>
              </>
            )}
            <div className={commonFormClasses.fieldContainer}>
              <FormControl
                disabled={viewMode || isPublished || disableEdit}
                className={commonFormClasses.inputLabelField}
                variant="standard"
                error={errorState.mandatory.multiSession}
              >
                <InputLabel id="multiSession-label" shrink className={commonFormClasses.inputLabelWithTooltip}>
                  {Translation('event.form.multiple_sessions')}
                  <span className={commonFormClasses.mandatory}>*</span>
                  <Tooltip arrow placement="top" title={Translation('event.form.multiple_sessions.tooltip')}>
                    <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Select
                  labelId="multiSession-label"
                  displayEmpty
                  value={formState.multiSession !== undefined ? formState.multiSession.toString() : ''}
                  renderValue={(selected) =>
                    selected ? (
                      Translation(`event.form.multiple_sessions.${selected}`)
                    ) : (
                      <span className={commonFormClasses.placeholder}>
                        {Translation('app.input.placeholder.please-select')}
                      </span>
                    )
                  }
                  onChange={(e) => {
                    const dismissFields = ['multiSession'];
                    const newFormState: Partial<BasicsFormState> = {
                      multiSession: e.target.value === 'true' ? true : false,
                    };
                    if (e.target.value === 'true') {
                      dismissFields.push('quota');
                      newFormState.quota = undefined;
                      newFormState.regAllowWalkIn = null;
                    } else {
                      dismissFields.push('sessionEnrollment');
                      newFormState.sessionEnrollment = undefined;
                      newFormState.sessions = [];
                    }
                    onDismissErrorHandler(dismissFields, true);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: {
                        value: newFormState,
                      },
                    });
                  }}
                >
                  {trueFalseOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {Translation(`event.form.multiple_sessions.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errorState.mandatory.multiSession && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
            {formState.multiSession === true && (
              <SessionSetting
                viewMode={viewMode}
                isPublished={isPublished}
                disableEdit={disableEdit}
                isAnnouncement={isAnnouncement}
                isVirtual={isVirtual}
                formState={formState}
                errorState={errorState}
                formDispatch={formDispatch}
                onDismissErrorHandler={onDismissErrorHandler}
              />
            )}
            {!isAnnouncement && formState.multiSession === false && (
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || isPublished || disableEdit}
                  fullWidth
                  variant="standard"
                  label={
                    <>
                      {Translation('event.form.event_quota')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  placeholder={Translation('event.form.event_quota.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  error={errorState.mandatory.quota || errorState.immediate.quotaFormat}
                  helperText={
                    errorState.immediate.quotaFormat
                      ? NUMBER_NA_ONLY_ERROR_TEXT
                      : errorState.mandatory.quota && MANDATORY_FIELD_ERROR_TEXT
                  }
                  value={formState.quota || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('quota', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'quota', value: e.target.value },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!viewMode && !disableEdit && (
        <div className={commonFormClasses.footerContainer}>
          <ButtonDialog
            dialogTxt={Translation('component.hint.cancel-warning')}
            btnClassName={classes.discardButton}
            btnTxt={Translation('app.button.discard')}
            onOk={() => {
              history.push(eventListPath);
            }}
          />
          <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
            {Translation('app.button.save_and_continue')}
          </Button>
        </div>
      )}
    </div>
  );
};
