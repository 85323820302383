import { useState, useEffect } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useLang } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { useDataProvider } from 'src/app/common/utils';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import {
  ParticipantPageEnum,
  RegistrationTypeEnum,
  EventFormCommonProps,
  ParticipantPageState,
  ParticipantOverview,
  SessionOverview,
  ParticipantItem,
  RemoveDialogState,
} from 'src/app/modules/event-v2/types';
import {
  ParticipantListParam,
  SendQRCodeParam,
  fetchParticipantOverview,
  fetchParticipantList,
  fetchParticipantDetailItem,
  exportParticipantList,
  exportRegistrationFormResult,
  deleteParticipantItem,
  sendQRCode,
} from 'src/app/modules/event-v2/network';

type HookProps = ComponentProps<EventFormCommonProps>;

const initialFilterState: ParticipantListParam = {
  registrationType: RegistrationTypeEnum.RSVP,
  firstName: '',
  lastName: '',
  role: '',
  referer: '',
  contact: [],
  regStartDate: null,
  regEndDate: null,
  qrCode: '',
  requestAttendanceStatus: '',
  sessionId: undefined,
  page: 1,
  limit: 20,
};

export const useParticipantList = ({ formCommonProps }: HookProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { id: eventId } = useParams<{ id?: string }>();
  const { eventItem } = formCommonProps;
  const initialPageState: ParticipantPageState = {
    page: ParticipantPageEnum.LIST,
    title: Translation('event.form.participant'),
  };

  const [pageState, setPageState] = useState<ParticipantPageState>(initialPageState);
  const [participantOverview, setParticipantOverview] = useState<ParticipantOverview>();
  const [selectedSession, setSelectedSession] = useState<SessionOverview>();
  const [filterState, setFilterState] = useState<ParticipantListParam>(initialFilterState);
  const [participantList, setParticipantList] = useState<PaginateList<ParticipantItem>>();
  const [selectedRows, setSelectedRows] = useState<ParticipantItem[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isExportingFormResult, setIsExportingFormResult] = useState<boolean>(false);
  const [removeDialogState, setRemoveDialogState] = useState<RemoveDialogState>({ open: false });

  const reloadOverview = async () => {
    if (eventId) {
      try {
        const res = await fetchParticipantOverview(eventId, dispatch);
        setParticipantOverview(res);
      } catch (err) {}
    }
  };

  const { isLoading, refreshData: refreshParticipantList } = useDataProvider<PaginateList<ParticipantItem>>(
    async () => {
      try {
        if (eventId) {
          return await fetchParticipantList(
            eventId,
            {
              ...filterState,
              regEndDate: filterState.regEndDate ? moment(filterState.regEndDate).endOf('minute').toDate() : null,
            },
            dispatch,
          );
        }
      } catch (err) {}
    },
    setParticipantList,
    false,
  );

  useEffect(() => {
    reloadOverview();
  }, [eventId]);

  const onResetPageState = () => {
    setPageState(initialPageState);
  };

  const onExport = async () => {
    if (eventId) {
      setIsExporting(true);
      try {
        await exportParticipantList(eventId, filterState, dispatch);
      } catch (err) {
      } finally {
        setIsExporting(false);
      }
    }
  };

  const onExportFormResult = async () => {
    if (eventId) {
      setIsExportingFormResult(true);
      try {
        await exportRegistrationFormResult(eventId, filterState, eventItem?.name[locale], dispatch);
      } catch (err) {
      } finally {
        setIsExportingFormResult(false);
      }
    }
  };

  const onRemove = async () => {
    if (eventId && removeDialogState.id) {
      try {
        await deleteParticipantItem(eventId, removeDialogState.id, dispatch);
        refreshParticipantList();
        reloadOverview();
      } catch (err) {}
      setRemoveDialogState({ open: false });
    }
  };

  const onSendQRCode = async () => {
    if (eventId) {
      try {
        const params: SendQRCodeParam = {
          registrationIds: selectedRows.map((item) => item.registrationId),
        };
        await sendQRCode(eventId, params, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Check-in QR code has been sent to the participant(s).',
            },
          ]),
        );
        setSelectedRows([]);
      } catch (err) {}
    }
  };

  const toParticipantDetail = async (id: string) => {
    if (eventId) {
      try {
        const res = await fetchParticipantDetailItem(eventId, id, dispatch);
        setPageState({
          page: ParticipantPageEnum.DETAIL,
          title: Translation('event.form.participant_details'),
          participantDetail: res,
        });
      } catch (err) {}
    }
  };

  return {
    eventId,
    pageState,
    participantOverview,
    selectedSession,
    filterState,
    participantList,
    selectedRows,
    isExporting,
    isExportingFormResult,
    removeDialogState,
    isLoading,
    setPageState,
    setSelectedSession,
    setFilterState,
    setSelectedRows,
    setRemoveDialogState,
    refreshParticipantList,
    onResetPageState,
    onExport,
    onExportFormResult,
    onRemove,
    onSendQRCode,
    toParticipantDetail,
  };
};
