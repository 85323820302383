export enum ExamTypeEnum {
  'Exam Type' = 'examType',
  'Exam Code' = 'examCode',
  'Exam Name(Local Language)' = 'examNameLocal',
  'Exam Name(English)' = 'examNameEnglish',
  'Exam Mode' = 'examMode',
  'Exam Date' = 'examDate',
  'Start Time' = 'startTime',
  'End Time' = 'endTime',
  'Venue' = 'examCentre',
  'Address' = 'address',
  'Last Registration Date' = 'lastRegistrationDate',
  'Quota' = 'quota',
  'Minimum Quota' = 'minimumQuota',
  'Remarks' = 'remarks',
  // 'isDeleted' = 'isDeleted',
}

export enum ExamResultsEnum {
  'Registration ID' = 'registrationId',
  'Applicant ID' = 'applicantId',
  'First Name' = 'firstname',
  'Last Name' = 'lastname',
  'Exam Code' = 'examCode',
  'Exam Type' = 'examType',
  'Exam Name(Local Language)' = 'examNameLocal',
  'Exam Name(English)' = 'examNameEnglish',
  'Exam Date' = 'examDate',
  'Start Time' = 'startTime',
  'End Time' = 'endTime',
  'Venue' = 'examCentre',
  'Address' = 'address',
  'Result' = 'result',
  'Score' = 'score',
  // 'Present' = 'present',
}

export enum RegistrationApprovalEventsEnum {
  'submit' = 'SUBMIT',
  'approve' = 'APPROVE',
  'return' = 'RETURN',
  'expire' = 'EXPIRE',
  'reject' = 'REJECT', // auto reject by system
  'cancel' = 'CANCEL',
  'complete' = 'COMPLETE',
}

export enum ExamEventsEnum {
  APPROVE = 'approve',
  RETURN = 'return',
  EXPIRE = 'expire',
  REJECT = 'reject',
  CANCEL = 'cancel',
  COMPLETE = 'complete',
  UPDATE_EXAM_RESULT = 'update',
}

export enum LicenseApprovalEventsEnum {
  submit = 'SUBMIT',
  approve = 'APPROVE',
  reject = 'REJECT',
  return = 'RETURN',
  expire = 'EXPIRE', // auto reject by system
}

export enum LicenseVerificationStatusEnum {
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  RETURNED = 'returned',
}

export enum RegistrationStatesFilterEnum {
  REGISTERED = 'registered',
  PENDING_APPROVAL = 'pending_approval',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  PASSED = 'passed',
  FAILED = 'failed',
  ABSENT = 'absent',
}

// may include legacy states that are still used for display purposes but not for filtering
export enum RegistrationApprovalStatesEnum {
  REGISTERED = 'registered',
  PENDING_APPROVAL = 'pending_approval',
  REJECTED = 'rejected',
  RETURNED = 'returned',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  PASSED = 'passed',
  FAILED = 'failed',
  ABSENT = 'absent',
}

export enum ResultTypeEnum {
  PASSED = 'passed',
  FAILED = 'failed',
  ABSENT = 'absent',
}

export enum PaymentTypeEnum {
  FULL_PAYMENT = 'full_payment',
  CODE_NOW_PAY_LATER = 'code_now_pay_later',
}

export enum TrainingFeeVerificationStatusEnum {
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  RETURNED = 'returned',
}

export enum TrainingFeeVerificationEventEnum {
  submit = 'SUBMIT',
  approve = 'APPROVE',
  reject = 'REJECT',
  return = 'RETURN',
  expire = 'EXPIRE', // auto reject by system
  resubmit = 'RESUBMIT',
}
