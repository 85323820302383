import { regionLocale } from 'src/app/i18n';
import { getLicenseExamSchedule } from 'src/app/modules/Recruitment/network/license-exam-crud';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';

export const getSchedule = async (id: string, dispatch: any): Promise<Record<CreateScheduleFields, any>> => {
  const res = await getLicenseExamSchedule(id, dispatch);
  const {
    examCode,
    examType,
    examName,
    examMode,
    examDate,
    examCentre,
    startTime,
    endTime,
    lastRegistrationDate,
    quota,
    minimumQuota,
    address,
    remarks,
  } = res;
  const result = {
    examCode,
    examType,
    examMode,
    examDate,
    examCentre,
    startTime,
    endTime,
    lastRegistrationDate,
    quota,
    minimumQuota,
    address,
    remarks,
  } as any;

  regionLocale.map((locale) => {
    if (locale === 'en') {
      result.examNameEnglish = examName[locale];
    } else {
      result.examNameLocal = examName[locale];
    }
  });

  return result;
};
