import { FC } from 'react';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  Select,
  MenuItem,
} from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { ParticipantUploadRsvpTypeEnum, ParticipantTemplateTypeEnum, EventItem } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles } from '../../../common';
import { useParticipantUploadStyles } from './participant-upload.style';
import { useParticipantUpload } from './participant-upload.hook';

type ParticipantUploadProps = {
  eventItem: EventItem;
  onBack: () => void;
};

export const ParticipantUpload: FC<ParticipantUploadProps> = ({ eventItem, onBack }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useParticipantUploadStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const {
    isDownloadingTemplate,
    isUploading,
    formState,
    errorState,
    formDispatch,
    handleFile,
    onDownloadTemplate,
    onConfirm,
  } = useParticipantUpload({
    eventItem,
    onBack,
  });

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div>
          <FormControl disabled={true} className={classes.field} variant="standard">
            <InputLabel id="mode-label" shrink className={commonFormClasses.inputLabel}>
              {Translation('event.form.upload_participant_rsvp')}
              <span className={commonFormClasses.mandatory}>*</span>
            </InputLabel>
            <Select labelId="mode-label" displayEmpty value={formState.rsvpType}>
              {map(ParticipantUploadRsvpTypeEnum, (option) => (
                <MenuItem key={option} value={option}>
                  {Translation(`event.form.upload_participant_rsvp.${option.toLowerCase()}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.chooseFileContainer}>
          <div className={classes.fileButtonContainer}>
            <input
              id={'upload-participant'}
              hidden
              type="file"
              accept=".xlsx"
              disabled={isUploading}
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={handleFile}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById('upload-participant')?.click()}
            >
              {Translation('app.button.chooseFile')}
            </Button>
            {formState.rsvpType === ParticipantUploadRsvpTypeEnum.REGISTRANT ? (
              <Link
                color="secondary"
                onClick={() => {
                  if (!isDownloadingTemplate) {
                    onDownloadTemplate(ParticipantTemplateTypeEnum.REGISTRANT);
                  }
                }}
              >
                {Translation('common.template')}
              </Link>
            ) : (
              <div className={classes.templateLinkContainer}>
                <Link
                  color="secondary"
                  onClick={() => {
                    if (!isDownloadingTemplate) {
                      onDownloadTemplate(ParticipantTemplateTypeEnum.ATTENDEE_WITH_DATA);
                    }
                  }}
                >
                  {Translation('event.form.upload_participant_rsvp.retrieve_existing')}
                </Link>
                {Translation('common.or')}
                <Link
                  color="secondary"
                  onClick={() => {
                    if (!isDownloadingTemplate) {
                      onDownloadTemplate(ParticipantTemplateTypeEnum.ATTENDEE);
                    }
                  }}
                >
                  {Translation('common.new_template')}
                </Link>
              </div>
            )}
          </div>
          {errorState.mandatory.file && <FormHelperText error>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          <div>{formState.filename}</div>
        </div>
        <div>
          {formState.rsvpType === ParticipantUploadRsvpTypeEnum.REGISTRANT && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.sendQrCode}
                  onChange={(e) =>
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'sendQrCode', value: e.target.checked },
                    })
                  }
                />
              }
              label={Translation('event.form.upload_participant_rsvp.option.send_qr_code')}
              labelPlacement="end"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.allowDupParticipants}
                onChange={(e) =>
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'allowDupParticipants', value: e.target.checked },
                  })
                }
              />
            }
            label={Translation('event.form.upload_participant_rsvp.option.allow_duplicate')}
            labelPlacement="end"
          />
          {formState.rsvpType === ParticipantUploadRsvpTypeEnum.REGISTRANT && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.publishToIrrelevantAgents}
                  onChange={(e) =>
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'publishToIrrelevantAgents', value: e.target.checked },
                    })
                  }
                />
              }
              label={Translation('event.form.upload_participant_rsvp.option.publish_outside_scope')}
              labelPlacement="end"
            />
          )}
        </div>
      </div>
      <div className={classes.footerContainer}>
        <Button variant="contained" color="inherit" onClick={() => onBack()}>
          {Translation('app.button.cancel')}
        </Button>
        <Button variant="contained" color="secondary" onClick={() => onConfirm()}>
          {Translation('app.button.confirm')}
        </Button>
      </div>
    </div>
  );
};
