import moment from 'moment';
import { useState, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useLang } from 'src/app/i18n';
import { createBlob, getBlob } from 'src/app/common/network';
import { ErrorFieldType, useErrorHandler, fileUpload } from 'src/app/common/utils';
import { ParticipantUploadRsvpTypeEnum, ParticipantTemplateTypeEnum, EventItem } from 'src/app/modules/event-v2/types';
import {
  UploadParticipantBody,
  getParticipantUploadTemplate,
  uploadParticipant,
} from 'src/app/modules/event-v2/network';
import { participantUploadFormReducer } from '../../../reducers';

type HookProps = {
  eventItem: EventItem;
  onBack: () => void;
};

export const useParticipantUpload = ({ eventItem, onBack }: HookProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();

  const [isDownloadingTemplate, setIsDownloadingTemplate] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [formState, formDispatch] = useReducer(participantUploadFormReducer, {
    rsvpType: moment().isBefore(eventItem.endTime)
      ? ParticipantUploadRsvpTypeEnum.REGISTRANT
      : ParticipantUploadRsvpTypeEnum.ATTENDEE,
    allowDupParticipants: true,
    sendQrCode: true,
    publishToIrrelevantAgents: true,
  });

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'file',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => !formState.blobId || !formState.filename,
    },
  ]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: Translation('global.max.file.type.allow', { num: '.xlsx' }),
            },
          ]),
        );
        return;
      }
      try {
        setIsUploading(true);
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'event' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          onDismissErrorHandler('file', true);
          formDispatch({
            type: 'SET_FORM_STATE',
            payload: { value: { blobId: result.blobId, filename: file.name } },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } catch (err) {
      } finally {
        setIsUploading(false);
      }
    }
  };

  const onDownloadTemplate = async (templateType: ParticipantTemplateTypeEnum) => {
    setIsDownloadingTemplate(true);
    try {
      await getParticipantUploadTemplate(eventItem._id, templateType, eventItem.name[locale], dispatch);
    } catch (err) {
    } finally {
      setIsDownloadingTemplate(false);
    }
  };

  const onConfirm = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: UploadParticipantBody = {
        rsvpType: formState.rsvpType,
        blobId: formState.blobId || '',
        filename: formState.filename || '',
        allowDupParticipants: formState.allowDupParticipants,
      };
      if (formState.rsvpType === ParticipantUploadRsvpTypeEnum.REGISTRANT) {
        body.sendQrCode = formState.sendQrCode;
        body.publishToIrrelevantAgents = formState.publishToIrrelevantAgents;
      }
      try {
        const res = await uploadParticipant(eventItem._id, body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: res.message,
            },
          ]),
        );
        onBack();
      } catch (err) {}
    }
  };

  return {
    isDownloadingTemplate,
    isUploading,
    formState,
    errorState,
    formDispatch,
    handleFile,
    onDownloadTemplate,
    onConfirm,
  };
};
