import {
  PaymentTypeEnum,
  RegistrationApprovalEventsEnum,
  RegistrationApprovalStatesEnum,
  ExamEventsEnum,
  ResultTypeEnum,
} from '../enum/license-exam.enum';

export type PaginateList<T extends Record<string, unknown>> = {
  total: number;
  totalPages: number;
  limit: number;
  items: T[];
  data: T[];
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalNumbers?: number;
  totalDocs?: number;
};
class ExamBaseParam {
  examType?: string;
  examDate?: string;
  examCode?: string;
  examCentre?: string;
  public examName?:
    | {
        [key: string]: string;
      }
    | string;
  [key: string]: unknown;
}

class ListResBaseParam {
  limit = 10;
  page = 1;
  sortBy?: string;
  orderBy?: string;
}

class langBaseParam {
  lang?: string;
}

export interface examOptionsParam extends langBaseParam {
  examType?: string;
}

export interface examScheduleParam extends ExamBaseParam, ListResBaseParam, langBaseParam {
  examName: string;
}

export interface examResultsParam extends ExamBaseParam, ListResBaseParam {
  examResults?: any;
  candidateId?: any;
}

export interface TimeSlotsListItem extends ExamBaseParam {
  id: string;
  lastUpdatedTime: string;
  lastUpdatedBy: string;
  startTime?: string;
  endTime?: string;
  examQuota?: number;
  examRemainingQuota?: number;
  examName?: {
    [key: string]: string;
  };
  activateStatus: ScheduleStatus;
}
export interface ExamResultsFileData extends ExamBaseParam {
  StartTime?: number;
  EndTime?: number;
  isPresent?: boolean;
  result?: string;
  score?: string;
}
export interface ExamScheduleFileData extends ExamBaseParam {
  startTime?: string;
  endTime?: string;
  lastRegistrationDate?: string;
  quota?: number;
  minimumQuota?: number;
  isDeleted?: boolean;
}

export type UpdateExamScheduleData = Pick<
  ExamScheduleFileData,
  'lastRegistrationDate' | 'quota' | 'minimumQuota' | 'remarks'
>;

export interface LicenseExamResultsListParam {
  ExamType?: string;
  ExamCode: string;
  ExamNameLocal?: string;
  ExamNameEnglish: string;
  ExamDate?: string;
  StartTime?: number;
  EndTime?: number;
  ExamCentre?: string;
  LastRegistrationDate?: string;
  Quota?: number;
  MinimumQuota?: number;
  isDeleted?: boolean;
}

export interface LicenseExamRegistrationExportParam {
  examType?: string;
  examName?: string;
  lang?: string;

  examDateFrom?: any;
  examDateTo?: any;
  status?: string[];
  orNumber?: string;
  registrationId?: string;
  applicantId?: string;
  firstName?: string;
  lastName?: string;
  examResult?: string;

  examDate?: string;
  examCode?: string;
  examCentre?: string;
}

export interface Operator {
  operatorId: string;
  operatorName: string;
  operatorRole: string;
}

export interface RegistrationApprovalHistory {
  status: string;
  event: string;
  remark: string;
  operator: Operator;
  updatedAt: string;
}

export interface UpdateExamResult {
  result: string;
  score: string;
}

export interface RegistrationItem extends ExamBaseParam {
  examName?: {
    [key: string]: string;
  };
  examDateFrom?: Date | null;
  examDateTo?: Date | null;
  orNumber?: string;
  registrationId?: string;
  applicantId?: string;
  firstname?: string;
  lastname?: string;
  isPresent: boolean;
  result?: string;
  score?: string;
  startTime?: string;
  endTime?: string;
  submissionTime?: string;
  address?: string;
  examFeeReceiptNo: string;
  licenseFeeReceiptNo: string;
  registrationApprovalStates: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string;
  operationHistoryList: RegistrationApprovalHistory[];
}

export interface RegistrationItemDetail extends RegistrationItem {
  region: string;
  channel: string;
  id: string;
  examFeeReceiptDate: string;
  paymentType: string;
  licenseFeeReceiptDate: string;
  licenseFeeOrNumberUsedBefore: boolean;
  examFeeOrNumberUsedBefore: boolean;
}

export type statusType = keyof typeof RegistrationApprovalStatesEnum;
export type approvalEventType = keyof typeof RegistrationApprovalEventsEnum;
export type eventType = keyof typeof ExamEventsEnum;
export type resultType = keyof typeof ResultTypeEnum;
export type PaymentType = keyof typeof PaymentTypeEnum;

export interface RegistrationListParam extends ExamBaseParam, ListResBaseParam, langBaseParam {
  examCodes?: string;
  examDateFrom?: string;
  examDateTo?: string;
  registrationApprovalStates?: statusType[];
  orNumbers?: string;
  registrationIds?: string;
  applicantIds?: string;
  firstnames?: string;
  lastnames?: string;
  result?: string;
  candidateId?: string;
}

export enum CreateScheduleFields {
  examCode = 'examCode',
  examType = 'examType',
  examMode = 'examMode',
  examNameEnglish = 'examNameEnglish',
  examNameLocal = 'examNameLocal',
  examDate = 'examDate',
  examCentre = 'examCentre',
  startTime = 'startTime',
  endTime = 'endTime',
  lastRegistrationDate = 'lastRegistrationDate',
  quota = 'quota',
  remainingQuota = 'minimumQuota',
  address = 'address',
  remark = 'remarks',
}

export enum CreateRegistrationFields {
  examCode = 'examCode',
  examType = 'examType',
  examMode = 'examMode',
  examNameEnglish = 'examNameEnglish',
  examNameLocal = 'examNameLocal',
  examDate = 'examDate',
  examCentre = 'examCentre',
  startTime = 'startTime',
  endTime = 'endTime',
  address = 'address',
  registrationId = 'registrationId',
  applicantId = 'applicantId',
  firstname = 'firstname',
  lastname = 'lastname',
  result = 'result',
  score = 'score',
}

export type GetScheduleResponse = {
  region: string;
  channel: string;
  id: string;
  examType: string;
  examCode: string;
  examName: Record<string, any>;
  examMode: string;
  examDate: string;
  startTime: string;
  endTime: string;
  examCentre: string;
  address: string;
  lastRegistrationDate: string;
  quota: number;
  minimumQuota: number;
  remainingQuota: number;
  updatedBy: string;
  lastUpdatedDate: string;
  isDeleted: boolean;
  remarks: string;
  baseExamInfo: BaseExamInfo;
};

type BaseExamInfo = {
  region: string;
  channel: string;
  id: string;
  examType: string;
  examCode: string;
  examName: Record<string, any>;
  examDate: string;
  startTime: string;
  endTime: string;
  examCentre: string;
  address: string;
  baseExamInfo: string;
};

export enum ScheduleStatus {
  ACTIVATE = 'ACTIVATE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface examModesType {
  displayKey: string;
  code: string;
  default: boolean;
}
