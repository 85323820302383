import { FC, Dispatch } from 'react';
import { map, filter } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { regionLocale } from 'src/app/i18n';
import {
  MANDATORY_FIELD_ERROR_TEXT,
  TIME_ERROR_TEXT,
  NUMBER_NA_ONLY_ERROR_TEXT,
  trueFalseOptions,
} from 'src/app/common/constants';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import { OnlineMeetingLinkDisplayEnum, SessionEnrollmentEnum, EventSessionItem } from 'src/app/modules/event-v2/types';
import { BasicsFormAction, SessionFormState } from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import { useCommonFormStyles, MultiLangAccordion } from 'src/app/modules/event-v2/pages/event-list/form/common';
import { useStyles } from './session-form.style';
import { useSessionForm } from './session-form.hook';
import { SubSessionForm } from './sub-session-form.component';

type SessionFormProps = {
  viewMode: boolean;
  isPublished: boolean;
  disableEdit: boolean;
  isAnnouncement: boolean;
  isVirtual: boolean;
  sessionNum: number;
  sessionItem?: EventSessionItem;
  updateBasicsForm: Dispatch<BasicsFormAction>;
  onClose: () => void;
};

export const SessionForm: FC<SessionFormProps> = ({
  viewMode,
  isPublished,
  disableEdit,
  isAnnouncement,
  isVirtual,
  sessionNum,
  sessionItem,
  updateBasicsForm,
  onClose,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formState, errorState, formDispatch, onDismissErrorHandler, onSubmit } = useSessionForm({
    isAnnouncement,
    isVirtual,
    sessionNum,
    sessionItem,
    updateBasicsForm,
    onClose,
  });

  return (
    <div className={classes.container}>
      <div className={commonFormClasses.doubleFieldContainer}>
        <FormControl
          disabled={viewMode || disableEdit}
          className={commonFormClasses.doubleField}
          error={errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime}
        >
          <InputLabel id="startTime-label" shrink className={commonFormClasses.inputLabel}>
            {Translation('event.form.session.start_time')}
            <span className={commonFormClasses.mandatory}>*</span>
          </InputLabel>
          <PruTimePicker
            disabled={viewMode || disableEdit}
            className={commonFormClasses.datePicker}
            ampm={false}
            slotProps={{
              textField: {
                variant: 'standard',
                error: errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime,
                helperText: errorState.mandatory.startTime && MANDATORY_FIELD_ERROR_TEXT,
                InputProps: {
                  endAdornment: <></>,
                },
              },
            }}
            value={formState.startTime}
            onChange={(date) => {
              onDismissErrorHandler('startTime', date);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: date } });
            }}
          />
        </FormControl>
        <FormControl
          disabled={viewMode || disableEdit}
          className={commonFormClasses.doubleField}
          error={errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime}
        >
          <InputLabel id="endTime-label" shrink className={commonFormClasses.inputLabel}>
            {Translation('event.form.session.end_time')}
            <span className={commonFormClasses.mandatory}>*</span>
          </InputLabel>
          <PruTimePicker
            disabled={viewMode || disableEdit}
            className={commonFormClasses.datePicker}
            ampm={false}
            slotProps={{
              textField: {
                variant: 'standard',
                error: errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime,
                helperText: errorState.immediate.endTimeBeforeStartTime
                  ? TIME_ERROR_TEXT
                  : errorState.mandatory.endTime && MANDATORY_FIELD_ERROR_TEXT,
                InputProps: {
                  endAdornment: <></>,
                },
              },
            }}
            value={formState.endTime}
            onChange={(date) => {
              onDismissErrorHandler('endTime', date);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: date } });
            }}
          />
        </FormControl>
      </div>
      <div className={classes.accordionContainer}>
        <MultiLangAccordion
          disabled={viewMode || disableEdit}
          accordionClassName={classes.accordion}
          error={(locale) => errorState.mandatory[`name-${locale}`]}
          fillInOnChange={(e, locale) => {
            if (e.target.checked) {
              onDismissErrorHandler([`name-${locale}`], true);
              formDispatch({
                type: 'FILL_IN_DETAIL',
                payload: { fromLocale: regionLocale[0], toLocale: locale },
              });
            }
          }}
          renderChildren={(locale) => (
            <>
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  variant="standard"
                  label={
                    <>
                      {Translation('event.form.session.name', {
                        num: sessionNum.toString(),
                      })}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  placeholder={Translation('event.form.session.name.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  error={errorState.mandatory[`name-${locale}`]}
                  helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale] || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(`name-${locale}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  variant="standard"
                  label={Translation('event.list.detail.venue')}
                  placeholder={Translation('event.form.session.venue.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  value={formState.venue[locale] || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'venue', value: { ...formState.venue, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
            </>
          )}
        />
      </div>
      <div className={classes.sectionContainer}>
        {isVirtual && (
          <>
            <div className={commonFormClasses.fieldContainer}>
              <TextField
                disabled={viewMode || disableEdit}
                fullWidth
                variant="standard"
                label={Translation('event.form.online_meeting_link')}
                placeholder={Translation('event.form.online_meeting_link.placeholder')}
                InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                error={errorState.immediate.onlineMeetingLinkFormat}
                helperText={errorState.immediate.onlineMeetingLinkFormat && Translation('component.form-link-text')}
                value={formState.onlineMeetingLink || ''}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'onlineMeetingLink', value: e.target.value },
                  });
                }}
              />
              <div className={commonFormClasses.remark}>{Translation('event.form.online_meeting_link.remark')}</div>
            </div>
            <div className={commonFormClasses.fieldContainer}>
              <FormControl disabled={viewMode || disableEdit}>
                <FormLabel className={commonFormClasses.formLabel}>
                  {Translation('event.form.online_meeting_link_display')}
                </FormLabel>
                <FormGroup className={commonFormClasses.radioGroup}>
                  {map(
                    filter(
                      OnlineMeetingLinkDisplayEnum,
                      (option) =>
                        !isAnnouncement || (isAnnouncement && option === OnlineMeetingLinkDisplayEnum.EVENT_DETAIL),
                    ),
                    (option) => (
                      <FormControlLabel
                        key={`onlineMeetingLinkDisplay-${option}`}
                        className={commonFormClasses.formControlLabel}
                        control={
                          <Checkbox
                            checked={!!formState.onlineMeetingLinkDisplay?.includes(option)}
                            onChange={(e) =>
                              formDispatch({
                                type: 'MODIFY_FIELD',
                                payload: {
                                  field: 'onlineMeetingLinkDisplay',
                                  value: e.target.checked
                                    ? [...(formState.onlineMeetingLinkDisplay || []), option]
                                    : formState.onlineMeetingLinkDisplay?.filter((item) => item !== option),
                                },
                              })
                            }
                          />
                        }
                        label={
                          <>
                            {Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}`)}
                            <Tooltip
                              arrow
                              placement="bottom"
                              title={Translation(
                                `event.form.online_meeting_link_display.${option.toLowerCase()}.tooltip`,
                              )}
                            >
                              <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                                <Info />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        labelPlacement="end"
                      />
                    ),
                  )}
                </FormGroup>
              </FormControl>
            </div>
          </>
        )}
        <div className={commonFormClasses.fieldContainer}>
          <FormControl
            disabled={viewMode || isPublished || disableEdit}
            className={commonFormClasses.inputLabelField}
            variant="standard"
            error={errorState.mandatory.multiSubSession}
          >
            <InputLabel id="multiSubSession-label" shrink className={commonFormClasses.inputLabelWithTooltip}>
              {Translation('event.form.session.multiple_sub_sessions')}
              <span className={commonFormClasses.mandatory}>*</span>
              <Tooltip arrow placement="top" title={Translation('event.form.multiple_sub_sessions.tooltip')}>
                <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                  <Info />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Select
              labelId="multiSubSession-label"
              displayEmpty
              value={formState.multiSubSession !== undefined ? formState.multiSubSession.toString() : ''}
              renderValue={(selected) =>
                selected ? (
                  Translation(`event.form.multiple_sessions.${selected}`)
                ) : (
                  <span className={commonFormClasses.placeholder}>
                    {Translation('app.input.placeholder.please-select')}
                  </span>
                )
              }
              onChange={(e) => {
                const dismissFields = ['multiSubSession'];
                const newFormState: Partial<SessionFormState> = {
                  multiSubSession: e.target.value === 'true' ? true : false,
                };
                if (e.target.value === 'true') {
                  dismissFields.push('quota');
                  newFormState.quota = undefined;
                } else {
                  dismissFields.push('subSessionEnrollment');
                  newFormState.subSessionEnrollment = undefined;
                  newFormState.subSessions = [];
                }
                onDismissErrorHandler(dismissFields, true);
                formDispatch({
                  type: 'SET_FORM_STATE',
                  payload: {
                    value: newFormState,
                  },
                });
              }}
            >
              {trueFalseOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {Translation(`event.form.multiple_sessions.${option}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.multiSubSession && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>
        {formState.multiSubSession === true && (
          <>
            <Button
              disabled={viewMode || isPublished || disableEdit}
              variant="contained"
              color="secondary"
              onClick={() => formDispatch({ type: 'ADD_SUB_SESSION', payload: { regionLocale } })}
            >
              {Translation('event.form.session.add_sub')}
            </Button>
            <div className={`${commonFormClasses.remark} ${classes.remarkMargin}`}>
              {Translation('event.form.session.add.remark')}
            </div>
            {formState.subSessions.map((subSessionItem, index) => (
              <SubSessionForm
                key={`sub-session-${index}`}
                viewMode={viewMode}
                isPublished={isPublished}
                disableEdit={disableEdit}
                isAnnouncement={isAnnouncement}
                isVirtual={isVirtual}
                subSessionNum={index + 1}
                subSessionItem={subSessionItem}
                errorState={errorState}
                onDismissErrorHandler={onDismissErrorHandler}
                updateSessionForm={(newSubSessionItem) =>
                  formDispatch({ type: 'MODIFY_SUB_SESSION', payload: { index, value: newSubSessionItem } })
                }
                removeSubSession={() => formDispatch({ type: 'REMOVE_SUB_SESSION', payload: { index } })}
              />
            ))}
            {!isAnnouncement && (
              <div className={commonFormClasses.fieldContainer}>
                <FormControl
                  disabled={viewMode || isPublished || disableEdit}
                  className={commonFormClasses.radioGroupContainer}
                  error={errorState.mandatory.subSessionEnrollment}
                >
                  <FormLabel className={commonFormClasses.radioGroupLabel}>
                    {Translation('event.form.session.session_enrollment')}
                    <span className={commonFormClasses.mandatory}>*</span>
                  </FormLabel>
                  <RadioGroup
                    className={commonFormClasses.radioGroup}
                    value={formState.subSessionEnrollment || ''}
                    onChange={(e) => {
                      onDismissErrorHandler('subSessionEnrollment', e.target.value);
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'subSessionEnrollment', value: e.target.value },
                      });
                    }}
                  >
                    {map(SessionEnrollmentEnum, (option) => (
                      <FormControlLabel
                        className={commonFormClasses.formControlLabel}
                        key={`session-enroll-${option}`}
                        control={<Radio />}
                        label={Translation(`event.form.session.sub_session_enrollment.${option.toLowerCase()}`)}
                        value={option}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {errorState.mandatory.subSessionEnrollment && (
                  <FormHelperText
                    className={commonFormClasses.radioGroupMargin}
                    error={errorState.mandatory.subSessionEnrollment}
                  >
                    {MANDATORY_FIELD_ERROR_TEXT}
                  </FormHelperText>
                )}
              </div>
            )}
          </>
        )}
        {!isAnnouncement && formState.multiSubSession === false && (
          <div className={commonFormClasses.fieldContainer}>
            <TextField
              disabled={viewMode || isPublished || disableEdit}
              fullWidth
              variant="standard"
              label={
                <>
                  {Translation('event.form.session.quota')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </>
              }
              placeholder={Translation('event.form.session.quota.placeholder')}
              InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
              error={errorState.mandatory.quota || errorState.immediate.quotaFormat}
              helperText={
                errorState.immediate.quotaFormat
                  ? NUMBER_NA_ONLY_ERROR_TEXT
                  : errorState.mandatory.quota && MANDATORY_FIELD_ERROR_TEXT
              }
              value={formState.quota || ''}
              onChange={(e) => {
                onDismissErrorHandler('quota', e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'quota', value: e.target.value },
                });
              }}
            />
          </div>
        )}
      </div>
      <div className={classes.footerContainer}>
        <Button variant="contained" color="inherit" onClick={() => onClose()}>
          {Translation('app.button.cancel')}
        </Button>
        <Button disabled={viewMode || disableEdit} variant="contained" color="secondary" onClick={() => onSubmit()}>
          {Translation('app.button.confirm')}
        </Button>
      </div>
    </div>
  );
};
