import React, { useCallback, useMemo, useState } from 'react';
import { CreateScheduleFields, examModesType } from 'src/app/modules/Recruitment/types/license-exam-types';
import { useForm } from 'src/app/common/components/Form';
import { createScheduleConfigs } from '../../../util/form-configs.util';
import { useIntl } from 'react-intl';
import { getConfigurations } from 'src/app/common/utils';
import { get } from 'lodash';

export interface CreateScheduleProps {
  initialValues?: Record<CreateScheduleFields, any>;
  disabled?: Record<CreateScheduleFields, boolean>;
  onSubmit: (value: Record<CreateScheduleFields, any>) => Promise<void>;
  onCancel: () => void;
  showSubmit: boolean;
}

export const useCreateSchedule = ({ onSubmit }: CreateScheduleProps) => {
  const [form] = useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const config = getConfigurations();
  const examModes: examModesType[] = get(config, 'Recruitment.examModes', []);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onFinish = useCallback(
    async (values: any) => {
      setConfirmLoading(true);
      await onSubmit(values);
      setConfirmLoading(false);
    },
    [onSubmit],
  );

  const examModeList = useMemo(() => {
    return examModes.map((mode: examModesType) => ({
      label: Translation(mode.displayKey),
      value: mode.code,
    }));
  }, []);

  const [
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examModeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
  ] = useMemo(() => createScheduleConfigs(), []);

  return {
    form,
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examModeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
    onFinish,
    confirmLoading,
    examModeList,
  };
};
