import { FC } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useLang } from 'src/app/i18n';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { ParticipantRoleEnum, ParticipantDetailItem } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles } from '../../../common';
import { useParticipantDetailStyles } from './participant-detail.style';

type ParticipantDetailProps = {
  multiSession: boolean;
  participantDetail: ParticipantDetailItem;
  onBack: () => void;
};

export const ParticipantDetail: FC<ParticipantDetailProps> = ({ multiSession, participantDetail, onBack }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useParticipantDetailStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();

  return (
    <div className={classes.detailContainer}>
      <div className={classes.backContainer}>
        <Button variant="contained" color="inherit" onClick={() => onBack()}>
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={commonFormClasses.fieldContainer}>
        <FormControl disabled={true} className={commonFormClasses.radioGroupContainer}>
          <FormLabel className={classes.radioGroupLabel}>
            {Translation('event.form.role')}
            <span className={commonFormClasses.mandatory}>*</span>
          </FormLabel>
          <RadioGroup className={commonFormClasses.radioGroup} value={participantDetail.role || ''}>
            {map(ParticipantRoleEnum, (option) => (
              <FormControlLabel
                className={commonFormClasses.formControlLabel}
                key={`role-${option}`}
                control={<Radio />}
                label={Translation(`event.form.role.${option.toLowerCase()}`)}
                value={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className={commonFormClasses.fieldContainer}>
        <TextField
          disabled={true}
          className={classes.textField}
          variant="standard"
          label={Translation('event.form.referred_agent_code')}
          InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
          value={participantDetail.referer || ''}
        />
      </div>
      <div className={commonFormClasses.fieldContainer}>
        <TextField
          disabled={true}
          className={classes.textField}
          variant="standard"
          label={
            <>
              {Translation('event.form.first_name')}
              <span className={commonFormClasses.mandatory}>*</span>
            </>
          }
          InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
          value={participantDetail.firstName || ''}
        />
      </div>
      <div className={commonFormClasses.fieldContainer}>
        <TextField
          disabled={true}
          className={classes.textField}
          variant="standard"
          label={Translation('event.form.last_name')}
          InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
          value={participantDetail.lastName || ''}
        />
      </div>
      <div className={commonFormClasses.fieldContainer}>
        <TextField
          disabled={true}
          className={classes.textField}
          variant="standard"
          label={Translation('event.form.contact.mobile')}
          InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
          value={participantDetail.phoneNumber || ''}
        />
      </div>
      <div className={commonFormClasses.fieldContainer}>
        <TextField
          disabled={true}
          className={classes.textField}
          variant="standard"
          label={Translation('event.form.email_address')}
          InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
          value={participantDetail.email || ''}
        />
      </div>
      {multiSession && participantDetail.sessions ? (
        <div className={commonFormClasses.fieldContainer}>
          <div className={classes.bottomSpacing}>
            {Translation('event.form.session_rsvp')}
            <span className={commonFormClasses.mandatory}>*</span>
          </div>
          <PruTable
            disableBulkSelect
            disablePagination
            disableToolbar
            renderChildren
            headerBtnDef={[]}
            operationDef={[]}
            columnDef={[
              {
                keyIndex: 'session',
                displayName: Translation('event.list.detail.sessions'),
                renderData: (_, index, parent) =>
                  parent
                    ? `${Translation('event.form.sub_session')} ${index + 1}`
                    : `${Translation('event.form.session')} ${index + 1}`,
              },
              {
                keyIndex: 'name',
                displayName: Translation('event.list.detail.session_name'),
                renderData: (row) => row.name[locale] || '-',
              },
              {
                keyIndex: 'session_time',
                displayName: Translation('event.form.session.time'),
                renderData: (row) =>
                  `${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).format('HH:mm')}` || '-',
              },
              {
                keyIndex: 'isRegistered',
                displayName: Translation('event.form.registration'),
                renderData: (row, _, parent) =>
                  !row.subSessions || row.subSessions.length === 0 || parent ? (row.isRegistered ? 'Yes' : 'No') : '-',
              },
              {
                keyIndex: 'attendanceStatus',
                displayName: Translation('event.form.attendance_status.attended'),
                renderData: (row, _, parent) =>
                  !row.subSessions || row.subSessions.length === 0 || parent ? (row.isAttended ? 'Yes' : 'No') : '-',
              },
              {
                keyIndex: 'checkInAt',
                displayName: Translation('event.form.check_in_time'),
                renderData: (row, _, parent) =>
                  !row.subSessions || row.subSessions.length === 0 || parent
                    ? getDefaultDisplayDate(row.checkInAt, 'datetime')
                    : '-',
              },
              {
                keyIndex: 'checkOutAt',
                displayName: Translation('event.form.check_out_time'),
                renderData: (row, _, parent) =>
                  !row.subSessions || row.subSessions.length === 0 || parent
                    ? getDefaultDisplayDate(row.checkOutAt, 'datetime')
                    : '-',
              },
              {
                keyIndex: 'duration',
                displayName: Translation('event.form.duration'),
                renderData: (row, _, parent) =>
                  !row.subSessions || row.subSessions.length === 0 || parent ? row.duration || '-' : '-',
              },
              {
                isId: true,
                hidden: true,
                keyIndex: 'key',
                childKeyIndex: 'subSessions',
                displayName: '',
                renderData: () => '',
              },
            ]}
            isLoading={false}
            dataSource={participantDetail.sessions}
          />
        </div>
      ) : (
        <>
          <div className={commonFormClasses.fieldContainer}>
            <FormControlLabel
              disabled={true}
              control={<Checkbox checked={participantDetail.isAttended} />}
              label={Translation('event.form.attendance_status.attended')}
              labelPlacement="end"
            />
          </div>
          <div className={commonFormClasses.doubleFieldContainer}>
            <FormControl disabled={true} className={commonFormClasses.doubleField}>
              <InputLabel shrink className={commonFormClasses.inputLabel}>
                {Translation('event.form.check_in_time')}
              </InputLabel>
              <PruTimePicker
                disabled={true}
                className={commonFormClasses.datePicker}
                ampm={false}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    InputProps: {
                      endAdornment: <></>,
                    },
                  },
                }}
                value={participantDetail.checkInAt || null}
              />
            </FormControl>
            <FormControl disabled={true} className={commonFormClasses.doubleField}>
              <InputLabel shrink className={commonFormClasses.inputLabel}>
                {Translation('event.form.check_out_time')}
              </InputLabel>
              <PruTimePicker
                disabled={true}
                className={commonFormClasses.datePicker}
                ampm={false}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    InputProps: {
                      endAdornment: <></>,
                    },
                  },
                }}
                value={participantDetail.checkOutAt || null}
              />
            </FormControl>
          </div>
          <div className={commonFormClasses.fieldContainer}>
            <TextField
              disabled={true}
              className={commonFormClasses.doubleField}
              variant="standard"
              label={Translation('event.form.duration')}
              InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
              value={participantDetail.duration || ''}
            />
          </div>
        </>
      )}
    </div>
  );
};
