import { Location } from 'history';
import { forEach } from 'lodash';
import { getDayStart, getDayEnd } from 'src/app/common/utils';

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export const getCurrentUrl = (location: Location) => {
  return location.pathname.split(/[?#]/)[0];
};

export const checkIsActive = (location: Location, url: string) => {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};

export const getQueryPath = (initialPath: string, params: Record<string, any>, disableTimeAdjustment?: boolean) => {
  let queryPath = initialPath;
  forEach(params, (param, key) => {
    if (Array.isArray(param)) {
      param.forEach((element) => {
        queryPath += `${key}[]=${encodeURIComponent(element)}&`;
      });
    } else if (param && param instanceof Date) {
      if (!disableTimeAdjustment) {
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        } else {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
      } else {
        queryPath += `${key}=${encodeURIComponent(param.toISOString())}&`;
      }
    } else {
      queryPath += param || typeof param === 'boolean' ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return queryPath;
};
