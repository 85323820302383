import React, { useCallback, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';
import { getSchedule } from '../util/api.util';
import { combineDateTime, formatParams } from '../util/api-helper.util';
import { updateLicenseExamSchedule } from 'src/app/modules/Recruitment/network/license-exam-crud';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getConfigurations } from 'src/app/common/utils';
import { get } from 'lodash';

export const useEditSchedule = () => {
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const config = getConfigurations();
  const examModes = get(config, 'Recruitment.examModes', []);
  const defaultExamMode = examModes?.find((mode: any) => mode.default);

  const { data, error, loading } = useRequest(
    async () => {
      const res = await getSchedule(id, dispatch);
      return {
        ...res,
        examMode: res.examMode ? res.examMode : defaultExamMode?.code,
        examDate: dayjs(res.examDate),
        startTime: combineDateTime(res.examDate, res.startTime),
        endTime: combineDateTime(res.examDate, res.endTime),
        lastRegistrationDate: dayjs(moment.utc(res.lastRegistrationDate).toISOString()),
      };
    },
    { manual: false },
  );

  const disabled = useMemo(
    () => ({
      examCode: true,
      examType: true,
      examMode: true,
      examNameEnglish: true,
      examNameLocal: true,
      examDate: true,
      examCentre: true,
      startTime: true,
      endTime: true,
      lastRegistrationDate: false,
      quota: false,
      minimumQuota: false,
      address: true,
      venue: true,
      remarks: false,
    }),
    [],
  );

  const onSubmit = useCallback(
    async (values: Record<CreateScheduleFields, any>) => {
      try {
        const { quota, minimumQuota, lastRegistrationDate, remarks } = formatParams(values);
        await updateLicenseExamSchedule(
          id,
          { payload: { quota, minimumQuota, lastRegistrationDate, remarks } },
          dispatch,
        );
        history.goBack();
      } catch (err) {
        console.error('createLicenseExamSchedule error: ', err);
      }
    },
    [id],
  );

  return {
    disabled,
    data,
    error,
    loading,
    onSubmit,
  };
};
