import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map, filter } from 'lodash';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Info } from '@mui/icons-material';
import { regionLocale } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT, TIME_ERROR_TEXT, NUMBER_NA_ONLY_ERROR_TEXT } from 'src/app/common/constants';
import { ErrorState, DismissErrorHandler } from 'src/app/common/utils';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import { OnlineMeetingLinkDisplayEnum } from 'src/app/modules/event-v2/types';
import { SessionFormState } from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import { useCommonFormStyles, MultiLangAccordion } from 'src/app/modules/event-v2/pages/event-list/form/common';
import { useStyles } from './session-form.style';
import { useSubSessionForm } from './sub-session-form.hook';

type SubSessionFormProps = {
  viewMode: boolean;
  isPublished: boolean;
  disableEdit: boolean;
  isAnnouncement: boolean;
  isVirtual: boolean;
  subSessionNum: number;
  subSessionItem: SessionFormState;
  errorState: ErrorState;
  onDismissErrorHandler: DismissErrorHandler;
  updateSessionForm: (newSubSessionItem: SessionFormState) => void;
  removeSubSession: () => void;
};

export const SubSessionForm: FC<SubSessionFormProps> = ({
  viewMode,
  isPublished,
  disableEdit,
  isAnnouncement,
  isVirtual,
  subSessionNum,
  subSessionItem,
  errorState,
  onDismissErrorHandler,
  updateSessionForm,
  removeSubSession,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formState, formDispatch } = useSubSessionForm({
    subSessionItem,
    updateSessionForm,
  });
  const errorNameSuffix = `-sub${subSessionNum}`;

  return (
    <div className={classes.subSessionContainer}>
      <div className={commonFormClasses.doubleFieldContainer}>
        <FormControl
          disabled={viewMode || disableEdit}
          className={commonFormClasses.doubleField}
          error={
            errorState.mandatory[`startTime${errorNameSuffix}`] ||
            errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
          }
        >
          <InputLabel id="startTime-label" shrink className={commonFormClasses.inputLabel}>
            {Translation('event.form.session.sub_start_time', { num: subSessionNum.toString() })}
            <span className={commonFormClasses.mandatory}>*</span>
          </InputLabel>
          <PruTimePicker
            disabled={viewMode || disableEdit}
            className={commonFormClasses.datePicker}
            ampm={false}
            slotProps={{
              textField: {
                variant: 'standard',
                error:
                  errorState.mandatory[`startTime${errorNameSuffix}`] ||
                  errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`],
                helperText: errorState.mandatory[`startTime${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT,
                InputProps: {
                  endAdornment: <></>,
                },
              },
            }}
            value={formState.startTime}
            onChange={(date) => {
              onDismissErrorHandler(`startTime${errorNameSuffix}`, date);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: date } });
            }}
          />
        </FormControl>
        <FormControl
          disabled={viewMode || disableEdit}
          className={commonFormClasses.doubleField}
          error={
            errorState.mandatory[`endTime${errorNameSuffix}`] ||
            errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
          }
        >
          <InputLabel id="endTime-label" shrink className={commonFormClasses.inputLabel}>
            {Translation('event.form.session.sub_end_time', { num: subSessionNum.toString() })}
            <span className={commonFormClasses.mandatory}>*</span>
          </InputLabel>
          <PruTimePicker
            disabled={viewMode || disableEdit}
            className={commonFormClasses.datePicker}
            ampm={false}
            slotProps={{
              textField: {
                variant: 'standard',
                error:
                  errorState.mandatory[`endTime${errorNameSuffix}`] ||
                  errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`],
                helperText: errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
                  ? TIME_ERROR_TEXT
                  : errorState.mandatory[`endTime${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT,
                InputProps: {
                  endAdornment: <></>,
                },
              },
            }}
            value={formState.endTime}
            onChange={(date) => {
              onDismissErrorHandler(`endTime${errorNameSuffix}`, date);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: date } });
            }}
          />
        </FormControl>
      </div>
      <div className={classes.accordionContainer}>
        <MultiLangAccordion
          disabled={viewMode || disableEdit}
          accordionClassName={classes.accordion}
          error={(locale) => errorState.mandatory[`name-${locale}${errorNameSuffix}`]}
          fillInOnChange={(e, locale) => {
            if (e.target.checked) {
              onDismissErrorHandler([`name-${locale}${errorNameSuffix}`], true);
              formDispatch({
                type: 'FILL_IN_DETAIL',
                payload: { fromLocale: regionLocale[0], toLocale: locale },
              });
            }
          }}
          renderChildren={(locale) => (
            <>
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  variant="standard"
                  label={
                    <>
                      {Translation('event.form.session.sub_name', { num: subSessionNum.toString() })}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  placeholder={Translation('event.form.session.name.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  error={errorState.mandatory[`name-${locale}${errorNameSuffix}`]}
                  helperText={errorState.mandatory[`name-${locale}${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale] || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(`name-${locale}${errorNameSuffix}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  variant="standard"
                  label={Translation('event.list.detail.venue')}
                  placeholder={Translation('event.form.session.venue.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  value={formState.venue[locale] || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'venue', value: { ...formState.venue, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
            </>
          )}
        />
      </div>
      {isVirtual && (
        <div className={classes.sectionContainer}>
          <div className={commonFormClasses.fieldContainer}>
            <TextField
              disabled={viewMode || disableEdit}
              fullWidth
              variant="standard"
              label={Translation('event.form.online_meeting_link')}
              placeholder={Translation('event.form.online_meeting_link.placeholder')}
              InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
              error={errorState.immediate[`onlineMeetingLinkFormat${errorNameSuffix}`]}
              helperText={
                errorState.immediate[`onlineMeetingLinkFormat${errorNameSuffix}`] &&
                Translation('component.form-link-text')
              }
              value={formState.onlineMeetingLink || ''}
              onChange={(e) => {
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'onlineMeetingLink', value: e.target.value },
                });
              }}
            />
            <div className={commonFormClasses.remark}>{Translation('event.form.online_meeting_link.remark')}</div>
          </div>
          <div className={commonFormClasses.fieldContainer}>
            <FormControl disabled={viewMode || disableEdit}>
              <FormLabel className={commonFormClasses.formLabel}>
                {Translation('event.form.online_meeting_link_display')}
              </FormLabel>
              <FormGroup className={commonFormClasses.radioGroup}>
                {map(
                  filter(
                    OnlineMeetingLinkDisplayEnum,
                    (option) =>
                      !isAnnouncement || (isAnnouncement && option === OnlineMeetingLinkDisplayEnum.EVENT_DETAIL),
                  ),
                  (option) => (
                    <FormControlLabel
                      key={`onlineMeetingLinkDisplay-${option}`}
                      className={commonFormClasses.formControlLabel}
                      control={
                        <Checkbox
                          checked={!!formState.onlineMeetingLinkDisplay?.includes(option)}
                          onChange={(e) =>
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'onlineMeetingLinkDisplay',
                                value: e.target.checked
                                  ? [...(formState.onlineMeetingLinkDisplay || []), option]
                                  : formState.onlineMeetingLinkDisplay?.filter((item) => item !== option),
                              },
                            })
                          }
                        />
                      }
                      label={
                        <>
                          {Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}`)}
                          <Tooltip
                            arrow
                            placement="bottom"
                            title={Translation(
                              `event.form.online_meeting_link_display.${option.toLowerCase()}.tooltip`,
                            )}
                          >
                            <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                      labelPlacement="end"
                    />
                  ),
                )}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      )}
      {!isAnnouncement && (
        <div className={classes.sectionContainer}>
          <div className={commonFormClasses.fieldContainer}>
            <TextField
              disabled={viewMode || isPublished || disableEdit}
              fullWidth
              variant="standard"
              label={
                <>
                  {Translation('event.form.session.quota')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </>
              }
              placeholder={Translation('event.form.session.quota.placeholder')}
              InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
              error={
                errorState.mandatory[`quota${errorNameSuffix}`] || errorState.immediate[`quotaFormat${errorNameSuffix}`]
              }
              helperText={
                errorState.immediate[`quotaFormat${errorNameSuffix}`]
                  ? NUMBER_NA_ONLY_ERROR_TEXT
                  : errorState.mandatory[`quota${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT
              }
              value={formState.quota || ''}
              onChange={(e) => {
                onDismissErrorHandler(`quota${errorNameSuffix}`, e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'quota', value: e.target.value },
                });
              }}
            />
          </div>
        </div>
      )}
      <div className={classes.subSessionDeleteContainer}>
        <IconButton
          disabled={viewMode || isPublished || disableEdit}
          className={classes.deleteIcon}
          onClick={() => removeSubSession()}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  );
};
