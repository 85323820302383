import React, { memo } from 'react';
import { FormControlLabel, Radio, RadioGroup as MuiRadioGroup, RadioGroupProps } from '@mui/material';
import { withStyles } from 'tss-react/mui';

interface StyledRadioGroupProps extends RadioGroupProps {
  options?: { label: string; value: string }[];
  disabled?: boolean;
}

const StyledRadioGroup = withStyles(MuiRadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

export const RadioGroup: React.FC<StyledRadioGroupProps> = memo(
  ({ options, disabled, ...rest }: StyledRadioGroupProps) => {
    return (
      <>
        <StyledRadioGroup {...rest}>
          {options?.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio disabled={disabled} sx={{ marginRight: '12px' }} />}
              label={label}
            />
          ))}
        </StyledRadioGroup>
      </>
    );
  },
);
