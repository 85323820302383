import { FC } from 'react';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import { StepStatusEnum, StepItem, StepIndicator, PruSteppedForm } from 'src/app/common/components/pru-stepped-form';
import { EventModeEnum, RegistrationMethodEnum, EventFormCommonProps } from '../../../types';
import { useEventDetailPage, ComponentEnum } from './event-detail-page.hook';
import { useStyles } from './event-detail-page.style';
import {
  EventBasicsForm,
  EventDetailsForm,
  EventRegistrationAttendanceForm,
  EventRegistrationForm,
  EventWalkInForm,
  EventPublishForm,
} from './components/event-setup';
import { EventAssistantList } from './components/event-assistant';
import { ParticipantList } from './components/participant';

const initialActiveStep: StepIndicator = {
  stepIndex: 0,
  child: {
    stepIndex: 0,
  },
};

const getInitialSteps = (
  Translation: (id: string) => string,
  getDefaultStatus: (component: ComponentEnum) => StepStatusEnum | undefined,
  classes: Record<string, string>,
): StepItem<EventFormCommonProps>[] => [
  {
    disableStatus: true,
    label: Translation('event.form.event_set_up'),
    customActiveStep: {
      stepIndex: 0,
      child: {
        stepIndex: 0,
      },
    },
    child: [
      {
        label: Translation('event.form.basics'),
        title: Translation('event.form.event_basics'),
        status: getDefaultStatus(ComponentEnum.BASICS),
        component: (props) => <EventBasicsForm {...props} />,
      },
      {
        label: Translation('event.form.details'),
        title: Translation('event.form.event_details'),
        status: getDefaultStatus(ComponentEnum.DETAILS),
        component: (props) => <EventDetailsForm {...props} />,
      },
      {
        hidden: (formCommonProps) => formCommonProps.eventItem?.mode === EventModeEnum.ANNOUNCEMENT,
        label: Translation('event.form.registration_attendance'),
        title: Translation('event.form.registration_attendance'),
        status: getDefaultStatus(ComponentEnum.REGISTRATION_ATTENDANCE),
        component: (props) => <EventRegistrationAttendanceForm {...props} />,
      },
      {
        hidden: (formCommonProps) =>
          formCommonProps.eventItem?.mode === EventModeEnum.ANNOUNCEMENT ||
          formCommonProps.eventItem?.regMethod !== RegistrationMethodEnum.TEMPLATE,
        label: Translation('event.form.registration_form'),
        title: Translation('event.form.registration_form'),
        status: getDefaultStatus(ComponentEnum.REGISTRATION_FORM),
        component: (props) => <EventRegistrationForm {...props} />,
      },
      {
        hidden: (formCommonProps) =>
          formCommonProps.eventItem?.mode === EventModeEnum.ANNOUNCEMENT ||
          formCommonProps.eventItem?.regMethod !== RegistrationMethodEnum.TEMPLATE ||
          (formCommonProps.eventItem?.regAllowWalkIn !== true &&
            !!formCommonProps.eventItem?.sessions.every(
              (session) =>
                !session.regAllowWalkIn && session.subSessions.every((subSession) => !subSession.regAllowWalkIn),
            )),
        label: Translation('event.form.walk_in_form'),
        title: Translation('event.form.walk_in_form'),
        status: getDefaultStatus(ComponentEnum.WALK_IN_FORM),
        component: (props) => <EventWalkInForm {...props} />,
      },
      {
        label: Translation('event.form.publish_setting'),
        title: Translation('event.form.publish_setting'),
        status: getDefaultStatus(ComponentEnum.PUBLISH_SETTING),
        component: (props) => <EventPublishForm {...props} />,
      },
    ],
  },
  {
    hidden: (formCommonProps) => formCommonProps.eventItem?.mode === EventModeEnum.ANNOUNCEMENT,
    disableStatus: true,
    label: Translation('event.form.event_assistant'),
    title: Translation('event.form.event_assistant'),
    component: (props) => <EventAssistantList {...props} />,
  },
  {
    hidden: (formCommonProps) => formCommonProps.eventItem?.mode === EventModeEnum.ANNOUNCEMENT,
    disableStatus: true,
    label: Translation('event.form.participant'),
    componentClassName: classes.transparentBg,
    component: (props) => <ParticipantList {...props} />,
  },
  // {
  //   disableStatus: true,
  //   label: Translation('event.form.communication_log'),
  //   component: () => <div>Communication Log</div>,
  // },
];

export const EventDetailPage: FC = () => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    formMode,
    isLoading,
    changesUnsaved,
    isReset,
    isPublished,
    disableEdit,
    walkInAllowed,
    eventCategoryList,
    eventTagList,
    eventItem,
    showLayoutSplashScreen,
    setIsLoading,
    setChangesUnsaved,
    setIsReset,
    reloadData,
    getDefaultStatus,
    onBack,
  } = useEventDetailPage();
  const formCommonProps: EventFormCommonProps = {
    formMode,
    isLoading,
    changesUnsaved,
    isReset,
    isPublished,
    disableEdit,
    walkInAllowed,
    eventCategoryList,
    eventTagList,
    eventItem,
    setIsLoading,
    setChangesUnsaved,
    setIsReset,
    reloadData,
  };
  const { classes } = useStyles();

  return (
    <>
      {showLayoutSplashScreen ? (
        <LayoutSplashScreen />
      ) : (
        <div style={{ height: '100%' }}>
          <PruSteppedForm
            initialActiveStep={initialActiveStep}
            initialSteps={getInitialSteps(Translation, getDefaultStatus, classes)}
            formCommonProps={formCommonProps}
            onBack={onBack}
          />
        </div>
      )}
    </>
  );
};
