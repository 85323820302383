export const templateFileData = [
  [
    'Exam Type',
    'Exam Code',
    'Exam Name(Local Language)',
    'Exam Name(English)',
    'Exam Mode',
    'Exam Date',
    'Start Time',
    'End Time',
    'Venue',
    'Address',
    'Last Registration Date',
    'Quota',
    'Minimum Quota',
    'Remarks',
  ],
  [
    'F',
    'AAJI',
    'Exam Paper 1',
    'Exam Paper 1',
    'Computer',
    '19/08/2023',
    '09:30',
    '15:30',
    'Jakarta Exam Centre',
    'Jakarta',
    '14/08/2023 12:30',
    50,
    40,
    undefined,
  ],
  [
    'S',
    'AASI',
    'Exam Paper 1',
    'Exam Paper 1',
    'Pencil and Paper',
    '19/08/2023',
    '14:30',
    '15:30',
    'Jakarta Exam Centre',
    'Jakarta',
    '14/08/2023 08:30',
    50,
    40,
    undefined,
  ],
];
