export enum Results {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  ABSENT = 'ABSENT',
}
export const templateFileData = [
  [
    'Registration ID',
    'Applicant ID',
    'First Name',
    'Last Name',
    'Exam Code',
    'Exam Type',
    'Exam Name(Local Language)',
    'Exam Name(English)',
    'Exam Date',
    'Start Time',
    'End Time',
    'Venue',
    'Address',
    'Result',
    'Score',
  ],
  [
    'A82331',
    'B2003',
    'firstName1',
    'lastName1',
    'C27341',
    'F',
    'Exam Paper (local)',
    'Exam Paper',
    '19/08/2023',
    '09:30',
    '15:30',
    'aa',
    'cc',
    'Passed',
    '80',
  ],
  [
    'A82332',
    'B2004',
    'firstName2',
    'lastName2',
    'C27341',
    'S',
    'Exam Paper (local)',
    'Exam Paper',
    '19/08/2023',
    '09:30',
    '15:30',
    'aa',
    'cc',
    'Failed',
    '0',
  ],
];
