import { RuleObject } from 'rc-field-form/es/interface';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import dayjs from 'dayjs';
import { Results } from '../results/results-template';

export const disableField = (key: CreateScheduleFields, disabled?: Record<CreateScheduleFields, boolean>) => {
  return !disabled ? false : disabled[key] ?? false;
};

type Options = {
  rule: RuleObject;
  emptyMsg?: string;
  errorMsg?: string;
  invalidMsg?: string;
};

export const isTimeValid = (time: string) => {
  return dayjs(time).isValid();
};

export const examDateValidator = (
  options: Options,
  examDate?: string,
  dayNow = dayjs(),
  compare = (examDate: any, dayNow: any) => dayjs(examDate).isBefore(dayNow, 'day'),
) => {
  const { rule, emptyMsg, invalidMsg, errorMsg } = options;
  let msg: any = undefined;
  if (!examDate) {
    if (rule.required) {
      msg = emptyMsg;
    }
  } else {
    if (!isTimeValid(examDate)) {
      msg = invalidMsg;
    } else {
      if (compare(examDate, dayNow)) {
        msg = errorMsg;
      }
    }
  }

  return msg;
};

export const startTimeValidator = (options: Options, startTime?: string) => {
  const { rule, emptyMsg, invalidMsg } = options;
  let msg: any = undefined;
  if (!startTime) {
    if (rule.required) {
      msg = emptyMsg;
    }
  } else {
    if (!isTimeValid(startTime)) {
      msg = invalidMsg;
    }
  }

  return msg;
};

export const endTimeValidator = (options: Options, startTime?: string, endTime?: string) => {
  const { rule, emptyMsg, errorMsg, invalidMsg } = options;
  let msg: any = undefined;
  if (!endTime) {
    if (rule.required) {
      msg = emptyMsg;
    }
  } else {
    if (!isTimeValid(endTime)) {
      msg = invalidMsg;
    } else if (startTime && dayjs(startTime).isAfter(endTime)) {
      msg = errorMsg;
    }
  }

  return msg;
};

export const lastRegistrationValidator = (
  options: Options & { day: number; futureDayErrorMsg: string },
  examDate?: string,
  lastRegistrationDate?: string,
  dayNow = dayjs(),
) => {
  const { rule, emptyMsg, errorMsg, day, invalidMsg, futureDayErrorMsg } = options;
  let msg: any = undefined;
  if (!lastRegistrationDate) {
    if (rule.required) {
      msg = emptyMsg;
    }
  } else {
    if (isTimeValid(lastRegistrationDate)) {
      if (dayjs(lastRegistrationDate).isBefore(dayNow)) {
        msg = futureDayErrorMsg;
      } else {
        if (!examDate) {
        } else {
          const registerDate = dayjs(lastRegistrationDate);
          const daysDifference = dayjs(examDate).diff(registerDate, 'day');
          if (daysDifference < day) {
            msg = errorMsg;
          } else {
            let count = 0;
            for (let i = 0; i < daysDifference; i++) {
              const nextDay = registerDate.add(i, 'day');
              const dayOfWeek = nextDay.day();
              if (!(dayOfWeek === 6 || dayOfWeek === 0)) {
                count = count + 1;
              }

              if (count >= day) {
                break;
              }
            }
            if (count < day) {
              msg = errorMsg;
            }
          }
        }
      }
    } else {
      msg = invalidMsg;
    }
  }

  return msg;
};

export const isPositiveInteger = (num?: string) => {
  if (!num) return false;
  return /^[1-9]\d*$/.test(num);
};

export const quotaValidator = (options: Options, quota?: string) => {
  const { rule, emptyMsg, errorMsg } = options;
  let msg: any = undefined;
  if (!quota) {
    if (rule.required) {
      msg = emptyMsg;
    }
  } else {
    if (!isPositiveInteger(quota)) {
      msg = errorMsg;
    }
  }

  return msg;
};

export const minimumQuotaValidator = (
  options: Options & { integerError: string },
  quota?: string,
  minimumQuota?: string,
) => {
  const { errorMsg, integerError } = options;
  let msg: any = undefined;
  if (!quota || !minimumQuota) {
  } else if (Number(minimumQuota) > Number(quota)) {
    msg = errorMsg;
  }

  if (minimumQuota) {
    if (Number(minimumQuota) === 0) {
      // can be zero
    } else if (!isPositiveInteger(minimumQuota)) {
      msg = integerError;
    }
  }

  return msg;
};

export const resultValidator = (options: Options, result?: string) => {
  const { rule, emptyMsg, errorMsg } = options;
  let msg: any = undefined;
  if (!result) {
    if (rule.required) {
      msg = emptyMsg;
    }
  } else {
    if (![Results.PASSED, Results.FAILED, Results.ABSENT].includes(result.toUpperCase() as Results)) {
      msg = errorMsg;
    }
  }

  return msg;
};

export const scoreValidator = (options: Options, score?: string, result?: string) => {
  const { rule, emptyMsg, errorMsg } = options;
  let msg: any = undefined;
  if (!score) {
    if (rule.required && result !== Results.ABSENT) {
      msg = emptyMsg;
    }
  } else {
    if (isNaN(Number(score)) || score.includes('-')) {
      msg = errorMsg;
    }
  }
  return msg;
};
